import Decoration from "components/ui/Decoration";
import DecorationTopRight from "components/ui/DecorationTopRight";
import LogoSebrae from "components/ui/LogoSebrae";
import RocketGirl from "components/ui/RocketGirl";
import { SignUpCredentials, useAuth } from "context/AuthContext";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/ui/Button";
import { darkInputClass } from "styles";
import { useEffect, useState } from "react";
import { LoadingModal } from "components/modal/LoadingModal";
import { toast } from "react-toastify";
interface IFormInputs {
	email?: string;
	cpf?: string;
	password?: string;
	confirmPassword?: string;
}

const registerSchema = yup.object({
	password: yup.string().required("Você não digitou sua senha"),
	confirmPassword: yup.string().required("Você não digitou sua senha"),
});

export default function Register() {
	const navigate = useNavigate();

	const { user, setRegisterUser, registerUser, login, signUp } = useAuth();
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		clearErrors,
		setError,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(registerSchema),
	});

	const onSubmit = async (data: IFormInputs) => {
		clearErrors();
		setLoading(true);
		try{
		if (registerUser) {
			if (data.password === data.confirmPassword) {
				const payload: SignUpCredentials = { ...data, ...registerUser };
				const result = await signUp(payload);

				toast(`🚀 ${result}`, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					});

					if(payload.email && payload.password){
						await login({username: payload.email, password:payload.password});
						navigate("/");
					}
					else{
						navigate("/login",{state:{success:true}});
					}
			} else {
				setError("confirmPassword", { message: "As senhas não conferem" });
			}
		}
	}catch(e:any){
		setError("confirmPassword", { message: e });
		setLoading(false);
	}
	};

	useEffect(() => {
		if (!registerUser) {
			navigate("/login");
		}
	}, [registerUser]);

	return (
		<>
			{loading && <LoadingModal />}
			<div className="h-screen pb-10 bg-blue">
				<div className="bg-blue-light min-h-[75%] rounded-bl-xl">
					<div className="flex flex-col items-center py-12 pb-40 md:py-16">
						<Link to="/">
							<LogoSebrae color="dark" className="w-24 md:w-40 pb-10" />
						</Link>
						<DecorationTopRight
							image="register"
							className="absolute w-32 md:w-56 top-0 right-0"
						/>
						<div className="absolute top-10 left-10">
							<Link to="/login">
								<BsArrowLeftCircle size={32} className="text-yellow" />
							</Link>
						</div>
						<h2 className="font-bold text-jeans text-xl md:text-3xl">
							Primeiro acesso
						</h2>
						<p className="mt-3 text-xl text-purple-dark">Olá, {registerUser?.name}!</p>
						<p className="">
							Crie sua senha de acesso ao {" "}
							<span className="text-yellow">Sebrae Networking</span>
						</p>
						<div className="py-8 pt-5 px-5 w-full md:w-96 md:px-0">
							{/* {!!registerUser && <div>{JSON.stringify(registerUser)}</div>} */}

							<form
								onSubmit={handleSubmit(onSubmit)}
								className="space-y-3 w-full"
							>
								<div>
									<label htmlFor="password" className="block">
										Senha
									</label>
									<input
										id="password"
										type="password"
										{...register("password")}
										className={darkInputClass}
										placeholder="Digite aqui seu email"
									/>
									<p className="text-yellow">{errors.password?.message}</p>
								</div>

								<div>
									<label htmlFor="password" className="block">
										Confirme sua Senha
									</label>
									<input
										id="confirmPassword"
										type="password"
										{...register("confirmPassword")}
										className={darkInputClass}
										placeholder="Digite aqui sua senha"
									/>
									<p className="text-yellow">
										{errors.confirmPassword?.message}
									</p>
								</div>

								<div className="mx-auto w-2/3 pt-3">
									<Button type="submit" className="w-full">
										Salvar e Entrar
									</Button>
								</div>

								<div className="mx-auto w-2/3 pt-3">
									<Button type="button" className="w-full bg-red" onClick={()=>navigate("/login")}>
										Cancelar
									</Button>
								</div>
							</form>
						</div>
						<Decoration
							image="register"
							className="fixed z-10 bottom-20 md:mt-10 lg:left-20 pointer-events-none w-[30%]"
						/>
					</div>
				</div>
			</div>
		</>
	);
}
