import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import { IoIosVideocam } from "react-icons/io";
import { MdVideocamOff } from "react-icons/md";
import { toast } from "react-toastify";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import maxmizeClose from "../../../assets/images/maximize-close.svg";
import maxmizeOpen from "../../../assets/images/maximize-open.svg";
import { useCall } from "context/CallContext";
import { useWebsocket } from "context/WebsocketContext";
import { useAuth } from "context/AuthContext";
import useEvent from "utils/use-event";

interface props {
	id: string;
}

export default function Controling({ id }: props) {
	const { sendMessage } = useWebsocket();
	const { user } = useAuth();
	const { maxmized, setMaxmized, setCall, callUser } = useCall();
	const { toggleWebcam, toggleMic, leave } = useMeeting();
	const { webcamOn, micOn } = useParticipant(id);

	useEvent("websocket-message", handleMessage);

	function handleMessage({ detail }: CustomEvent) {
		try {
			if (detail.type === "endCall") {
				setCall(undefined);
				leave();

				toast(`😅 ${detail ? detail.request : "Um usuário"} saiu da chamada!`, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		} catch (e) {
			console.log(e);
		}
	}

	function handleLeave() {
		sendMessage({
			type: "endCall",
			request: user?.name,
			from: user?._id,
			to: callUser,
		});

		setCall(undefined);
		leave();
	}

	return (
		<ul className="z-10 list-none flex justify-center md:justify-start absolute md:top-5 bottom-5 md:bottom-auto md:right-4 right-0 w-full md:w-auto  border-t solid border-white md:border-none pt-4 md:pt-0">
			<li>
				<button
					className="hidden md:flex h-full mr-2 border border-solid border-white rounded-md w-10 items-center justify-center"
					onClick={() => {
						setMaxmized(!maxmized);
					}}
				>
					<img
						className="h-5"
						src={maxmized ? maxmizeOpen : maxmizeClose}
						alt="Maxmizar"
					/>
				</button>
			</li>
			<li>
				<button
					onClick={() => toggleWebcam()}
					className="h-full mr-2 md:border border-solid border-white rounded-md w-10  flex items-center justify-center"
				>
					{webcamOn ? (
						<IoIosVideocam color="white" size={30} />
					) : (
						<MdVideocamOff color="white" size={30} />
					)}
				</button>
			</li>

			<li>
				<button
					onClick={() => toggleMic()}
					className="h-full mr-2 md:border border-solid border-white rounded-md w-10  flex items-center justify-center"
				>
					{micOn ? (
						<BsFillMicFill color="white" size={30} />
					) : (
						<BsFillMicMuteFill color="white" size={30} />
					)}
				</button>
			</li>
			<li>
				<button
					className="w-36 md:w-auto h-full mr-2 bg-red-light text-white px-4 md:py-2 rounded-3xl"
					onClick={() => handleLeave()}
				>
					Desligar
				</button>
			</li>
		</ul>
	);
}
