import { useAuth } from "context/AuthContext";
import { useCall } from "context/CallContext";
import { useModal } from "context/ModalContext";
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
	fetchNetworkingCard,
	searchMyNetwork,
} from "services/networking.service";
import { IEvent } from "types/event";
import { IUserCard } from "types/networking";
import useEvent from "utils/use-event";

interface NetworkingContextData {
	// connect: () => void;
	// disconnect: () => void;
	// sendMessage: (message:any) => void;
	updateInviteCounter: (total: number, reset: boolean) => void;
	updateMessagesCounter: (total: number, reset: boolean) => void;
	setEventActive: any
	loadMyNetwork: () => void;
	loadNetworkCard: () => void;
	eventActive: IEvent | undefined;
	networkingCard?: IUserCard;
	myNetwork?: IUserCard[];
	messagesCounter: any;
	inviteCounter: any;
}

interface NetworkingContextProps {
	children: ReactNode;
}

export const NetworkingContext = createContext({} as NetworkingContextData);

function NetworkingProvider({ children }: NetworkingContextProps) {
	const location = useLocation();

	const { showModal } = useModal();
	const { user, refreshUser, isAuthenticated } = useAuth();
	const { setCall } = useCall();

	const [networkingCard, setNetworkingCard] = useState<IUserCard>();
	const [myNetwork, setMyNetwork] = useState<any[]>();
	const [eventActive, setEventActive] = useState<IEvent | undefined>();

	const [inviteCounter, setInviteCounter] = useState(0);
	const [messagesCounter, setMessagesCounter] = useState(0);

	useEvent("websocket-message", handleMessage);

	async function loadMyNetwork() {
		if (!user) return;
		const myNetworkResult = await searchMyNetwork(user._id);

		if (myNetworkResult.length) {
			const pendingInvites = myNetworkResult.filter(
				(item: any) => item.status === "pending"
			);
			if (pendingInvites && pendingInvites.length > 0) {
				updateInviteCounter(pendingInvites.length);
			}
			setMyNetwork(myNetworkResult);
		} else {
			setMyNetwork([]);
			updateInviteCounter(0, true);
		}
	}

	async function loadNetworkCard() {
		if (!user) return;

		const networkingCardResult = await fetchNetworkingCard(user._id);

		// Recupera a ultima versão do perfil
		if (networkingCardResult) {
			setNetworkingCard(networkingCardResult);
		} else {
			setNetworkingCard({});
		}
	}

	function updateMessagesCounter(total = 0, reset = false) {
		if (total) {
			setMessagesCounter(total);
		} else if (reset) {
			setMessagesCounter(0);
		} else {
			if (location.pathname !== "/mensagens") {
				setMessagesCounter(messagesCounter + 1);
			}
		}
	}

	function updateInviteCounter(total = 0, reset = false) {
		if (total) {
			setInviteCounter(total);
		} else if (reset) {
			setInviteCounter(0);
		} else {
			setInviteCounter(inviteCounter + 1);
		}
	}

	async function handleMessage({ detail }: CustomEvent) {
		try {
			if (detail.type === "message") {
				updateMessagesCounter();
			} else if (detail.type === "connect") {
				const sender = detail.from;
				const senderData = await fetchNetworkingCard(sender);
				updateInviteCounter();
				refreshUser();
				showModal("invite", {
					invite: {
						...senderData,
						request: detail.request,
						event: detail.event,
					},
				});
			} else if (detail.type === "connect-accept") {
				const sender = detail.from;
				const senderData = (await fetchNetworkingCard(sender)) as IUserCard;
				refreshUser();
				toast(
					`🚀 ${
						senderData ? senderData.name : "Um usuário"
					} aceitou o seu convite!`,
					{
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
			} else if (detail.type === "call") {
				const sender = detail.from;
				const senderData = await fetchNetworkingCard(sender);
				showModal("call", {
					invite: {
						...senderData,
						request: detail.request,
						event: detail.event,
					},
				});
			} else if (detail.type === "callRejected") {
				const sender = detail.from;
				const senderData = await fetchNetworkingCard(sender);
				toast(
					`😅 ${
						senderData ? senderData.name : "Um usuário"
					} recusou a chamada!`,
					{
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);

				setCall(undefined);
			}
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		if (!isAuthenticated) {
			setEventActive(undefined);
			updateInviteCounter(0, true);
			updateMessagesCounter(0, true);
		}
	}, [isAuthenticated]);

	useEffect(() => {
		updateInviteCounter(0, true);
		updateMessagesCounter(0, true);
	}, [eventActive]);

	return (
		<NetworkingContext.Provider
			value={{
				setEventActive,
				eventActive,
				networkingCard,
				myNetwork,
				loadMyNetwork,
				loadNetworkCard,
				updateMessagesCounter,
				updateInviteCounter,
				messagesCounter,
				inviteCounter,
			}}
		>
			{children}
		</NetworkingContext.Provider>
	);
}

function useNetworking() {
	const context = useContext(NetworkingContext);
	if (context === undefined) {
		throw new Error("useNetworking must be used within a NetworkingProvider");
	}
	return context;
}

export { NetworkingProvider, useNetworking };
