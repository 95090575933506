import { MeetingProvider, MeetingConsumer } from "@videosdk.live/react-sdk";
import { useAuth } from "context/AuthContext";
import MeetingGrid from "./MeetingGrid";

interface props {
	meet: string;
	token: string;
}

function Video({ meet, token }: props) {
	const { user } = useAuth();

	return (
		<MeetingProvider
			config={{
				meetingId: meet,
				micEnabled: true,
				webcamEnabled: true,
				name: user?.name,
			}}
			token={token}
		>
			<MeetingConsumer>{() => <MeetingGrid />}</MeetingConsumer>
		</MeetingProvider>
	);
}

export default Video;
