import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { AuthContext } from "context/AuthContext";
import Button from "components/ui/Button";
import DecorationTopRight from "components/ui/DecorationTopRight";
import Logo from "components/ui/Logo";
import LogoSebrae from "components/ui/LogoSebrae";
import RocketGirl from "components/ui/RocketGirl";

export function NotFound() {

	useEffect(() => {}, []);

	return (
		<div className="pb-10">
			<div className="bg-blue rounded-bl-xl">
			<div className="flex flex-col items-center text-xl md:text-2xl py-12 pb-40 md:py-16">
			<Link to="/"><LogoSebrae className="w-24 md:w-40 pb-10" /></Link>
			<DecorationTopRight className="absolute w-32 md:w-56 top-0 right-0" />
			<div className="font-bold text-white text-xl md:text-3xl">
				Página <strong className="text-yellow">não encontrada</strong>
			</div>
			<RocketGirl className="absolute z-10 top-48 md:mt-10 lg:left-20 lg:top-36 lg:w-1/3 animate-fly pointer-events-none" />
		</div>
			</div>
		</div>
	);
}
