import { H2 } from "components/typography";
import Button from "components/ui/Button";
import { UIElementProps } from "types/prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { clearInputClass, inputClass } from "styles";
import { useEffect, useRef, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import { BiTrash } from "react-icons/bi";
import {
	BsFacebook,
	BsFillImageFill,
	BsInstagram,
	BsLinkedin,
	BsTwitter,
} from "react-icons/bs";
import { ICategory, ISubCategory, IUserCard } from "types/networking";
import { useAuth } from "context/AuthContext";
import { updateUserProfile, uploadPicture } from "services/profile.service";
import {
	listCategories,
	saveNetworkingCard,
} from "services/networking.service";

import { toast } from "react-toastify";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import Select, { components, PlaceholderProps } from "react-select";
import { customStyles } from "pages/Dashboard";
import { useNetworking } from "context/NetworkingContext";

type ProfileFormProps = UIElementProps & {
	onChange: () => void;
};

const profileSchema = yup.object({
	email: yup.string().email("Email inválido"),
	color: yup.string(),
	bio: yup.string(),
	phone: yup.string(),
	company: yup.string(),
	position: yup.string(),
	country: yup.string(),
	city: yup.string(),
	state: yup.string(),
	linkedin: yup.string(),
	facebook: yup.string(),
	twitter: yup.string(),
	instagram: yup.string(),
	youtube: yup.string(),
	segment: yup.string(),
	category: yup.string(),
	keywords: yup.array().of(yup.string()),
	interests: yup.array().of(yup.object()),
});

const colors = [
	"#EF7794",
	"#BA77EF",
	"#83BF30",
	"#F5DD29",
	"#FE8F0D",
	"#DD5254",
	"#CD8DE5",
	"#777DEF",
	"#5BA4CF",
	"#29CCE5",
	"#6DECA9",
	"#FB79C7",
	"#C4C4C4",
	"#172B4D",
];

let imagePreviewTimeout: any = null;

export default function ProfileForm(props: ProfileFormProps) {
	const { onChange } = props;
	const [loading, setLoading] = useState(false);
	const [keywords, setKeywords] = useState<string[]>();
	const [selectedColor, setSelectedColor] = useState("");
	const { user, refreshUser } = useAuth();
	const { networkingCard, loadNetworkCard } = useNetworking();
	const [optionsSegmento, setOptionsSegmento] = useState<ICategory[]>([]);
	const [optionsInteresse, setOptionsInteresse] = useState<ISubCategory[]>([]);
	const [selectedSegmento, setSelectedSegmento] = useState<any>();
	const [selectedInteresse, setSelectedInteresse] = useState<string>();
	const [eventSelected, setEventSelected] = useState<any>(
		sessionStorage.getItem("EventId")
	);
	const segmentoRef = useRef<any>(null);
	const interesseRef = useRef<any>(null);

	const [image, setImage] = useState("");
	const editor = useRef<any>(null);

	const {
		register,
		handleSubmit,
		clearErrors,
		setError,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<IUserCard>({
		resolver: yupResolver(profileSchema),
	});

	const onSubmit = async (data: IUserCard) => {
		try {
			if (loading) return;

			setLoading(true);
			clearErrors();

			let profileImage = null;
			if (image) {
				const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
				if (dataUrl) {
					const uploadResult = await uploadPicture(dataUrl);
					const url = uploadResult.url;
					profileImage = {
						small: { url },
						medium: { url },
						original: { url },
					};
				}
			}

			console.log(profileImage);
			if (selectedSegmento) {
				data.segment = selectedSegmento.value;
			}
			if (selectedInteresse) {
				data.category = selectedInteresse;
			}

			await updateUserProfile({
				_id: user?._id,
				name: data.name,
				image: profileImage || user?.image,
			});

			await saveNetworkingCard({
				_id: user?._id,
				...data,
				image: profileImage || networkingCard?.image,
			});

			refreshUser();
			onChange();
			loadNetworkCard();

			toast(`🚀 Seu perfil foi atualizado com sucesso!`, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			// await login(data as LoginCredentials);
		} catch (err) {
			// setLoginError("password",{message:"Usuário ou senha inválidos"});
		}
		// setLoading(false);
	};

	function setColor(color: string) {
		setValue("color", color);
		setSelectedColor(color);
	}

	function handleImageChange() {
		if (imagePreviewTimeout) {
			clearTimeout(imagePreviewTimeout);
		}

		imagePreviewTimeout = setTimeout(() => {
			getImageUrl();
		}, 1000);
	}

	function handleClearImage() {
		const profileImage = document.getElementById("profile-image");
		if (profileImage) {
			if (networkingCard?.image) {
				profileImage.style.backgroundImage = `url(${networkingCard?.image})`;
			} else {
				profileImage.style.backgroundImage = ``;
			}
		}
		setImage("");
	}

	const getImageUrl = async () => {
		const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
		const result = await fetch(dataUrl);
		const blob = await result.blob();

		const profileImage = document.getElementById("profile-image");
		if (profileImage) {
			profileImage.style.backgroundImage = `url(${window.URL.createObjectURL(
				blob
			)})`;
		}

		// return window.URL.createObjectURL(blob)
	};

	useEffect(() => {
		setValue("keywords", keywords);
	}, [keywords]);

	useEffect(() => {
		async function loadData() {
			try {
				await loadNetworkCard();
				const data = await listCategories(eventSelected);
				const segmentos = data.map((item: any) => ({
					value: item.key,
					label: item.title,
					subCategories: item.subCategories,
				}));
				setOptionsSegmento(segmentos);
			} catch (e) {}
		}
		loadData();
	}, []);

	useEffect(() => {
		try {
			if (!optionsSegmento.length || !selectedSegmento) return;
			const segmento: any = optionsSegmento.find(
				(item: any) => item.label === selectedSegmento.label
			);
			const interesse = segmento.subCategories.map((item: any) => ({
				value: item._id,
				label: item.name,
			}));
			setOptionsInteresse(interesse);
		} catch (e) {
			console.log(e);
		}
	}, [optionsSegmento, selectedSegmento]);

	const Placeholder = (props: PlaceholderProps<any>) => {
		return <components.Placeholder {...props} />;
	};

	useEffect(() => {
		if (networkingCard) {
			if (!networkingCard.keywords) {
				setKeywords([]);
			}
			Object.keys(networkingCard).forEach((key: any) => {
				const card = networkingCard as any;
				if (key === "keywords") {
					setKeywords(card[key] || []);
				} else if (key === "color") {
					setColor(card[key] || "");
				} else {
					setValue(key, card[key] || "");
				}
			});
		}
	}, [networkingCard]);

	if (!networkingCard) return null;

	function handleChangeSegmento(e: any) {
		if (!e) {
			setOptionsInteresse([]);
			return;
		}
		setSelectedSegmento(e);
	}
	function handleChangeInteresse(e: any) {
		if (!e) {
			return;
		}
		setSelectedInteresse(e.value);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="divide-y flex flex-col">
				<div className="p-5">
					<H2>Insira sua foto! Clique na imagem </H2>
					<div className="relative z-2 mt-2 rounded-lg bg-gray-200 p-3 flex flex-row items-center justify-center space-x-4">
						<Dropzone
							onDrop={(dropped: any) => setImage(dropped[0])}
							multiple={false}
							maxFiles={1}
							noClick={!!image}
						>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps()}>
									<AvatarEditor
										ref={(e) => (editor.current = e)}
										onImageChange={handleImageChange}
										width={300}
										height={450}
										image={image}
										style={{ width: "200px", height: "300px" }}
									/>
									<input {...getInputProps()} />
								</div>
							)}
						</Dropzone>
						<div>
							<Dropzone
								onDrop={(dropped: any) => setImage(dropped[0])}
								multiple={false}
								maxFiles={1}
								noClick={!!image}
							>
								{({ getRootProps, getInputProps }) => (
									<div
										{...getRootProps()}
										className="rounded-full flex flex-col items-center justify-center text-green bg-white w-16 h-16 mb-1"
									>
										<BsFillImageFill size={32} />
										<p>Upload</p>
										<input {...getInputProps()} />
									</div>
								)}
							</Dropzone>
							<button
								type="button"
								onClick={handleClearImage}
								className="rounded-full flex flex-col items-center justify-center text-red bg-white w-16 h-16"
							>
								<BiTrash size={32} />
								<p>Deletar</p>
							</button>
						</div>
					</div>
				</div>
				<div className="p-5">
					<H2>Selecione a cor detalhe do seu cartão e perfil</H2>
					<div className="grid grid-cols-7 gap-2 mt-3">
						{colors.map((color, index) => (
							<div
								key={color}
								onClick={() => setColor(color)}
								className={`cursor-pointer w-full h-8 rounded-lg ${
									selectedColor === color ? "ring-2 ring-blue-600" : ""
								}`}
								style={{ background: color }}
							>
								&nbsp;
							</div>
						))}
					</div>
				</div>
				<div className="p-5">
					<H2>Qual é o seu nome?</H2>
					<input
						id="name"
						type="text"
						{...register("name")}
						className={clearInputClass}
						placeholder="Digite aqui seu nome"
					/>
				</div>
				<div className="p-5">
					<H2>Onde você trabalha?</H2>
					<input
						id="company"
						type="text"
						{...register("company")}
						className={clearInputClass}
						placeholder="Digite aqui o nome da sua empresa"
					/>
				</div>
				<div className="p-5">
					<H2>Qual é o seu cargo?</H2>
					<input
						id="position"
						type="text"
						{...register("position")}
						className={clearInputClass}
						placeholder="Digite aqui o seu cargo"
					/>
				</div>
				<div className="p-5">
					<H2>Sobre você / sua empresa (100 caracteres)</H2>
					<textarea
						id="bio"
						{...register("bio")}
						className={clearInputClass}
						placeholder="Conte-nos um pouco sobre você"
					/>
				</div>
				<div className="p-5">
					<H2>qual sua principal Área de Atuação?</H2>
					<Select
						id="segment"
						ref={(e) => (segmentoRef.current = e)}
						isClearable
						components={{ Placeholder }}
						placeholder={
							networkingCard.segment
								? networkingCard.segment
								: "Área de Atuação"
						}
						options={optionsSegmento}
						className="flex-1"
						styles={customStyles}
						onChange={handleChangeSegmento}
					/>
				</div>
				<div className="p-5">
					<H2>qual sua categoria Profissional?</H2>
					<Select
						id="category"
						ref={(e) => (interesseRef.current = e)}
						isClearable
						components={{ Placeholder }}
						placeholder={
							networkingCard.category
								? networkingCard.category
								: "Categoria Profissional"
						}
						options={optionsInteresse}
						className="flex-1"
						styles={customStyles}
						onChange={handleChangeInteresse}
						defaultValue={selectedInteresse}
					/>
				</div>
				<div className="p-5">
					<H2>Quais são os seus interesses?</H2>
					{!!keywords && (
						<TagsInput
							value={keywords}
							onChange={setKeywords}
							name="keywords"
							placeHolder="Adicionar interesse"
						/>
					)}
				</div>
				<div className="p-5">
					<H2>E-mail comercial para contato</H2>
					<input
						id="email"
						type="text"
						{...register("email")}
						className={clearInputClass}
						placeholder="Digite aqui seu email comercial"
						defaultValue={user?.email}
					/>
				</div>
				<div className="p-5">
					<H2>Telefone comercial para contato</H2>
					<input
						id="phone"
						type="text"
						{...register("phone")}
						className={clearInputClass}
						placeholder="Digite aqui seu telefone comercial"
					/>
				</div>
				<div className="p-5 space-y-3 text-purple">
					<H2>Insira o link das suas redes</H2>
					<div className="flex flex-row items-center space-x-2">
						<BsLinkedin size={24} />
						<input
							id="linkedin"
							type="text"
							{...register("linkedin")}
							className={clearInputClass}
							placeholder="URL do seu LinkedIn"
						/>
					</div>
					<div className="flex flex-row items-center space-x-2">
						<BsTwitter size={24} />
						<input
							id="twitter"
							type="text"
							{...register("twitter")}
							className={clearInputClass}
							placeholder="URL do seu perfil no Twitter"
						/>
					</div>
					<div className="flex flex-row items-center space-x-2">
						<BsFacebook size={24} />
						<input
							id="facebook"
							type="text"
							{...register("facebook")}
							className={clearInputClass}
							placeholder="URL do seu perfil no facebook"
						/>
					</div>
					<div className="flex flex-row items-center space-x-2">
						<BsInstagram size={24} />
						<input
							id="instagram"
							type="text"
							{...register("instagram")}
							className={clearInputClass}
							placeholder="URL do seu perfil no instagram"
						/>
					</div>
				</div>
				<div className="p-5 text-center">
					{!loading && (
						<Button type="submit" className="w-40 py-1">
							Salvar
						</Button>
					)}
					{loading && (
						<Button disabled={true} className="w-64 py-1">
							Salvando alterações...
						</Button>
					)}
				</div>
			</div>
		</form>
	);
}
