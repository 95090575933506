import React, { ReactNode, useContext } from "react";

import { Navigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { useAuth } from "context/AuthContext";
import { useNetworking } from "context/NetworkingContext";
import EventPicker from "components/event-picker";

interface IPrivateRoute {
	permissions?: string[];
	roles?: string[];
	redirectTo?: string;
	children: ReactNode;
}

export function PrivateRoute({
	permissions,
	roles,
	redirectTo = "/login",
	children,
}: IPrivateRoute) {
	const { isAuthenticated } = useAuth();
	const { eventActive} = useNetworking();
	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1024px)",
	});

	const isLargeDesktop = useMediaQuery({
		query: "(min-width: 1440px)",
	});

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	if (!isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }

	if (!eventActive) return <EventPicker />;

	return (
		<>
		{children}
		</>
	);
}
