import { CallRequestModal } from "components/modal/CallRequestModal";
import { InviteModal } from "components/modal/InviteModal";
import { MessageModal } from "components/modal/MessageModal";
import { SendInviteModal } from "components/modal/SendInviteModal";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface ModalContextData {
	showModal: (modalId: string, data?: any) => void;
	closeModal: () => void;
	openModal?: string;
	modalData: any;
}

interface ModalProviderProps {
	children: ReactNode;
}

export const ModalContext = createContext({} as ModalContextData);

function ModalProvider({ children }: ModalProviderProps) {

	const location = useLocation();

	const [openModal,setOpenModal] = useState<string|undefined>();
	const [modalData,setModalData] = useState<any>();

	useEffect(() => {
		closeModal();
	}, [location]);

	function showModal(modalId:string,data?:any){
		setModalData(data);
		setOpenModal(modalId);
	}

	function closeModal(){
		setOpenModal(undefined);
		setModalData(undefined);
	}

	return (
		<ModalContext.Provider
			value={{
				showModal,
				closeModal,
				openModal,
				modalData
			}}
		>
			{children}
			<InviteModal />
			<CallRequestModal />
			<SendInviteModal />
			<MessageModal />
		</ModalContext.Provider>
	);
}

function useModal() {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useWebsocket must be used within a WebsocketProvider')
  }
  return context
}

export {ModalProvider, useModal}
