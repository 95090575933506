import { api } from "./api";

type LoginRequestData = {
	username: string;
	password: string;
	apiKey?: string;
	grant_type?: string;
};

export function loginRequest(data: LoginRequestData) {
	data["apiKey"] = process.env.REACT_APP_APIKEY;
	data["grant_type"] = "password";

	const params = new URLSearchParams(window.location.search);
	const userToken = params.get("token");

	return api
		.post("/auth/token", userToken)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			// console.error(error.response.data.message);
			throw error.response.data;
		});
}

export function fetchUser() {
	const params = new URLSearchParams(window.location.search);
	const userToken = params.get("token");
	if (userToken) {localStorage.setItem("Token" , userToken);}

	return api
		.get("/player/me/status", {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("Token"),
			},
		})
		.then((response) => {
			const user = response.data;
			if (user) {
				return user;
			} else {
				document.dispatchEvent(new CustomEvent("signout"));
			}
		})
		.catch((error) => {
			console.log(error);
		});
}

export function registerUserRequest(user: any) {
	const payload = {
		...user,
		cpf: user.cpf.replace(/\D+/g, ""),
	};
	return api
		.put("/database/registration__c", payload, {
			headers: {
				Authorization:
					"Basic " +
					btoa(
						`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_REGISTRATION_SECRETKEY}`
					),
			},
		})
		.then((response) => {
			const result = response.data;
			// return result;
			if (result.status === 200) {
				return result.message;
			} else {
				throw result.message;
			}
		})
		.catch((error) => {
			throw error;
		});

	// const player = {...user, extra: {cpf: user.cpf}};

	// return api
	// 	.post("/player", player, {
	// 		headers: {
	// 			Authorization:
	// 				"Basic " +
	// 				btoa(
	// 					`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_REGISTRATION_SECRETKEY}`
	// 				),
	// 		},
	// 	})
	// 	.then((response) => {
	// 		const result = response.data;
	// 		return result;
	// 	})
	// 	.catch((error) => {
	// 		console.log(error);
	// 	});
}

export async function authorizedsUsersRequest(user: any) {
	const payload = [
		{
			$match: {
				_id: user.email,
				cpf: user.cpf.replace(/\D+/g, ""),
			},
		},
	];
	return await api
		.post("/database/authorizeds__c/aggregate", payload, {
			headers: {
				Authorization:
					"Basic " +
					btoa(
						`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_REGISTRATION_SECRETKEY}`
					),
			},
		})
		.then((response) => {
			const result = response.data;
			if (result.length == 0) {
				throw "E-mail e CPF não cadastrados em nenhum evento.";
			} else {
				return result[0];
			}
		})
		.catch((error) => {
			throw error;
		});
}

export async function recoverPasswordRequest(data: LoginRequestData) {
	data["apiKey"] = process.env.REACT_APP_APIKEY;
	data["grant_type"] = "password";

	try {
		const response = await api.post("/auth/token", data);
		return response.data.access_token;
	} catch (error) {
		console.log(error);
	}
}
