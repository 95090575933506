import Button from "components/ui/Button";
import HeaderEvent from "components/ui/HeaderEvent";
import { useAuth } from "context/AuthContext";
import { useNetworking } from "context/NetworkingContext";
import { customStyles } from "pages/Dashboard";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Select, { components, PlaceholderProps } from "react-select";
import { fetchEvent } from "services/networking.service";
import { IEvent } from "types/event";
import EventIcon from "../../assets/images/event-icon.png";

export default function EventPicker() {
	const {user} = useAuth();
	const { eventActive, setEventActive } = useNetworking();
	const [list, setList] = useState<any>([]);
	const [eventSelected, setEventSelected] = useState<any>(
		sessionStorage.getItem("EventId")
	);
	const [eventList, setEventList] = useState<IEvent[]>([]);
	const [loading,setLoading] = useState(true);
	const { handleSubmit, getValues, setValue } = useForm({
		defaultValues: {
			search: "",
		},
	});

	const segmentoRef = useRef<any>(null);

	async function onSubmit(data: { search?: string }) {

		if (!data.search) return;

		sessionStorage.setItem("EventId", eventSelected);

		const item = eventList.find((e) => e._id === data.search);

		setEventActive(item);
	}

	const Placeholder = (props: PlaceholderProps<any>) => {
		return <components.Placeholder {...props} />;
	};

	function handleChangeSegmento(e: any) {
		const value = e?.value || "";
		setValue("search", value);
		setEventSelected(value);
	}

	useEffect(() => {
		async function load() {

			console.log(user);

			if(!user) return;

			try {

				setLoading(true);
				const event = await fetchEvent(user._id);

				const events = event.map((item) => ({
					value: item._id,
					label: item.name,
				}));

				setList(events);
				setEventList(event);

				if(event.length === 1){
					setEventSelected(event[0]._id);
					setEventActive(event[0]);
				}

				if (eventSelected) {
					const item = event.find((e) => e._id == eventSelected);
					setEventActive(item);
				}

				setLoading(false);

			} catch (e) {
				console.log(e);
				setLoading(false);
			}
		}

		load();
	}, [user]);

	if(loading) return null;

	return (
		<>
			{eventActive ? (
				""
			) : (
				<div>
					<HeaderEvent />

					<picture className="absolute -left-8 md:left-10 bottom-0">
						<img className="h-60" src={EventIcon} alt="Prancheta" />
					</picture>

					<form id="searchForm" onSubmit={handleSubmit(onSubmit)}>

						<div className="relative z-20 flex flex-col items-center space-y-5 mt-10">

						<p className="mt-3 text-xl text-purple-dark">Selecione o evento que deseja participar:</p>

							<div className="w-[90%] md:w-[20%]">
								<Select
									ref={(e) => (segmentoRef.current = e)}
									isClearable
									components={{ Placeholder }}
									placeholder={"Selecione o evento"}
									options={list}
									className="flex-1"
									styles={customStyles}
									onChange={handleChangeSegmento}
								/>
							</div>

							<Button type="submit" disabled={!eventSelected} className="w-[90%] md:w-64 disabled:opacity-50">
								ENTRAR NO EVENTO
							</Button>
						</div>
					</form>
				</div>
			)}
		</>
	);
}
