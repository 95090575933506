import { H1 } from "components/typography";
import { useModal } from "context/ModalContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";

import call from "../../assets/images/call.svg";
import { useWebsocket } from "context/WebsocketContext";
import { useAuth } from "context/AuthContext";
import { useCall } from "context/CallContext";
import { useNavigate } from "react-router-dom";

export function CallRequestModal(props: any, onAnswer?: Function) {
	const modalID = "call";
	const [modalIsOpen, setIsOpen] = useState(false);
	const [invite, setInvite] = useState<any>();
	const { children } = props;

	const { closeModal, openModal, modalData } = useModal();
	const { acceptCall } = useCall();
	const { sendMessage } = useWebsocket();
	const { user } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		console.log("current open modal", openModal, modalID);
		setIsOpen(openModal === modalID);
	}, [openModal]);

	useEffect(() => {
		if (modalData) setInvite(modalData.invite);
	}, [modalData]);

	ReactModal.setAppElement("#modal");

	function handleAccepted() {
		sendMessage({
			type: "callAccepted",
			request: invite.request,
			from: user?._id,
			to: invite._id,
		});

		acceptCall({ meet: invite.request, player: invite._id });

		closeModal();

		navigate("/mensagens");
	}

	function handleRejected() {
		sendMessage({
			type: "callRejected",
			request: invite.request,
			from: user?._id,
			to: invite._id,
		});

		closeModal();
	}

	return (
		<ReactModal
			shouldCloseOnOverlayClick={false}
			isOpen={modalIsOpen}
			contentLabel="Call Modal"
			portalClassName=""
			overlayClassName="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-40 block"
			contentElement={() => (
				<div className="bg-blue rounded-md md:absolute md:bottom-10 md:right-10 w-full md:w-[26%] h-full md:h-2/6">
					<picture className="hidden md:absolute top-2 left-0">
						<img src={call} alt="Call" />
					</picture>

					<picture className="md:hidden h-[70%] text-center block bg-yellow rounded-b-lg overflow-hidden relative mb-3">
						{invite?.image?.url ? (
							<img
								className="rounded-b-lg w-full bg-yellow"
								src={invite?.image?.url}
								alt="Call"
							/>
						) : (
							<div className="font-bold text-2xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
								{invite.name.substring(0, 1)}
							</div>
						)}
					</picture>

					<div className="text-center md:mt-4">
						<p className="text-white text-2xl mb-1 drop-shadow-lg">
							{invite.name}
						</p>
						<p className="text-white drop-shadow-lg">Está Ligando</p>
					</div>

					<div className="flex justify-between px-20 mt-10">
						<div className="felx flex-col items-center justify-center">
							<button
								className="bg-red drop-shadow-xl rounded-full p-6 mb-2"
								onClick={() => handleRejected()}
							>
								<BsFillMicMuteFill color="white" size={50} />
							</button>
							<p className="text-white drop-shadow-lg text-center">Desligar</p>
						</div>
						<div className="flex flex-col items-center justify-center">
							<button
								className="bg-green drop-shadow-xl rounded-full p-6 mb-2"
								onClick={() => handleAccepted()}
							>
								<BsFillMicFill color="white" size={50} />
							</button>

							<p className="text-white drop-shadow-lg">Atender</p>
						</div>
					</div>
				</div>
			)}
		/>
	);
}
