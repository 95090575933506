type ButtonProps = {
	children: React.ReactNode;
	onClick?: () => void;
	href?: string;
	className?: string;
	disabled?: boolean;
	type?: "button" | "submit" | "reset";
	tag?: "button" | "a" | "input";
	style?: React.CSSProperties;
	color?:
		| "primary"
		| "secondary"
		| "success"
		| "danger"
		| "warning"
		| "info"
		| "light"
		| "dark"
		| "outline";
	[key: string]: any;
};

const buttonColor: any = {
	primary: "bg-yellow text-white hover:ring hover:ring-white ",
	success: "bg-green text-white",
	outline: "bg-transparent text-yellow ring-2 ring-yellow",
	danger: "bg-red text-white",
};

export default function Button(props: ButtonProps) {
	const { children, onClick, className, disabled, type, style, color } = props;

	return (
		<button
			{...props}
			className={`px-2 ${className} ${
				buttonColor[color || "primary"]
			} rounded-full font-bold`}
		>
			<div className="p-1">{children}</div>
		</button>
	);
}
