import CardLoading from "components/loading/CardLoading";
import PeopleList from "components/PeopleList";
import Header from "components/ui/Header";
import Select, {
	components,
	PlaceholderProps,
	StylesConfig,
} from "react-select";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Button from "components/ui/Button";
import { H1 } from "components/typography";
import { Input } from "components/form";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import {
	INetworkFind,
	listCategories,
	searchNetworking,
} from "services/networking.service";
import { ICategory, ISubCategory, IUserCard } from "types/networking";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import UserCard from "components/user-card";
import Decoration from "components/ui/Decoration";
import { useNetworking } from "context/NetworkingContext";
import ReactModal from "react-modal";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { actionLog } from "services/actionlog.service";

export const customStyles: StylesConfig = {
	control: (base, { isDisabled }) => ({
		...base,
		border: "none",
		borderRadius: "100vw",
		background: isDisabled ? "#808080" : "var(--color-purple)",
		color: "var(--color-white)",
	}),
	valueContainer: (base) => ({
		...base,
		color: "#FFFFFF",
	}),
	input: (base) => ({
		...base,
		color: "#FFFFFF",
	}),
	singleValue: (base) => ({
		...base,
		color: "#FFFFFF",
	}),
	placeholder: (base) => ({
		...base,
		color: "#FFFFFF",
		fontSize: "0.85rem",
	}),
	clearIndicator: (base) => ({
		...base,
		color: "#FFFFFF",
	}),
	// option: (base) => ({
	// 	...base,
	// 	// color: "#FFFFFF",
	// }),
};

export function Dashboard() {
	const { handleSubmit, control, getValues, resetField, setValue } = useForm({
		defaultValues: {
			search: "",
		},
	});

	const { eventActive, loadMyNetwork, loadNetworkCard } = useNetworking();

	const { user } = useAuth();
	const { networkingCard } = useNetworking();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [searchResult, setSearchResult] = useState<IUserCard[]>([]);

	const [optionsSegmento, setOptionsSegmento] = useState<ICategory[]>([]);
	const [optionsInteresse, setOptionsInteresse] = useState<ISubCategory[]>([]);

	const [selectedSegmento, setSelectedSegmento] = useState<string>();
	const [selectedInteresse, setSelectedInteresse] = useState<string>();
	const [eventSelected, setEventSelected] = useState<any>(
		sessionStorage.getItem("EventId")
	);
	const segmentoRef = useRef<any>(null);
	const interesseRef = useRef<any>(null);

	const [modalIsOpen, setIsOpen] = useState(false);

	const [profileNetworkingCard, setProfileNetworkingCard] =
		useState<IUserCard>();

	function handleChangeSegmento(e: any) {
		interesseRef.current.setValue("");
		if (!e) {
			setOptionsInteresse([]);
			setSelectedSegmento("");
			return;
		}
		setSelectedSegmento(e.value);
	}

	function handleChangeInteresse(e: any) {
		// search({
		// 	sub_categories: e.value,
		// });
		if (!e) {
			setSelectedInteresse("");
			return;
		}
		setSelectedInteresse(e.value);
	}

	async function onSubmit(data: any) {
		search({
			search: data.search,
			category: selectedSegmento ? selectedSegmento : "",
			sub_categories: selectedInteresse ? selectedInteresse : "",
		});
	}

	function onCardTagClick(tag: string) {
		setValue("search", tag, { shouldDirty: true });
		search({ search: tag });
	}

	function renderList() {
		if (searchResult.length === 0) {
			return renderEmptyList();
		}
		return (
			<div className="grid md:grid-cols-3 xl:grid-cols-4 gap-6">
				{searchResult.map((item, index) => {
					return (
						<UserCard key={index} data={item} onTagClick={onCardTagClick} />
					);
				})}
			</div>
		);
	}

	function renderEmptyList() {
		return (
			<div className="text-center">
				<H1>Nenhum resultado encontrado</H1>
			</div>
		);
	}

	function renderLoading() {
		return (
			<div className="grid md:grid-cols-4 gap-6">
				<CardLoading />
				<CardLoading />
				<CardLoading />
				<CardLoading />
			</div>
		);
	}

	const handleActionLog = (title: any, segmento: any, category: any) => {
		actionLog("interaction", {
			type: "busca_networking",
			content_type: segmento,
			content_title: title,
			category: category,
			location: "sebrae_ro_networking",
		});
	};

	async function search(params?: INetworkFind) {
		try {
			setLoading(true);
			const searchPayload = { ...params };
			const result = await searchNetworking(searchPayload);
			handleActionLog(params?.search, params?.category, params?.sub_categories);
			setSearchResult(result);
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	}

	useEffect(() => {
		async function loadData() {
			try {
				const data = await listCategories(eventSelected);
				const segmentos = data.map((item: any) => ({
					value: item.key,
					label: item.title,
					subCategories: item.subCategories,
				}));
				setOptionsSegmento(segmentos);
				segmentoRef.current.setValue("");
			} catch (e) {}
		}
		if (eventActive) {
			loadData();
			loadMyNetwork();
			search();
		}

		scrollTo({ top: 0 });
	}, [eventActive]);

	useEffect(() => {
		try {
			setSelectedInteresse("");
			if (!optionsSegmento.length || !selectedSegmento) return;
			const segmento: any = optionsSegmento.find(
				(item) => item.value === selectedSegmento
			);
			const interesse = segmento.subCategories.map((item: any) => ({
				value: item._id,
				label: item.name,
			}));
			setOptionsInteresse(interesse);
		} catch (e) {
			console.log(e);
		}
	}, [optionsSegmento, selectedSegmento]);

	const Placeholder = (props: PlaceholderProps<any>) => {
		return <components.Placeholder {...props} />;
	};

	useEffect(() => {
		if (networkingCard && !networkingCard.name) {
			navigate("/perfil");
			return;
		}
	}, [networkingCard]);

	useEffect(() => {
		loadNetworkCard();
	}, []);

	return (
		<>
			<div className="pb-10">
				<Header />
				<div className="px-5">
					<div className="mx-auto 2xl:w-[1380px] space-y-8">
						<div>
							<H1 className="text-jeans">faça uma busca</H1>
							<div>
								<form id="searchForm" onSubmit={handleSubmit(onSubmit)}>
									<div className="relative z-20 flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-2">
										<Select
											ref={(e) => (segmentoRef.current = e)}
											isClearable
											components={{ Placeholder }}
											placeholder={"Área de Atuação"}
											options={optionsSegmento}
											className="flex-1"
											styles={customStyles}
											onChange={handleChangeSegmento}
										/>
										<Select
											ref={(e) => (interesseRef.current = e)}
											isClearable
											isDisabled={!selectedSegmento}
											components={{ Placeholder }}
											placeholder={
												!selectedSegmento
													? "Selecione uma Área de Atuação"
													: "Categoria Profissional"
											}
											options={optionsInteresse}
											className="flex-1"
											styles={customStyles}
											onChange={handleChangeInteresse}
											defaultValue={selectedInteresse}
										/>
										<div className="flex-1 relative">
											<Controller
												name="search"
												control={control}
												render={({ field, fieldState }) => (
													<>
														<input
															{...field}
															className="pl-3 pr-8 py-0 border-0 rounded-full bg-purple text-white w-full h-10 placeholder-white placeholder:text-sm"
															placeholder="Busca por nome, empresa ou palavras-chave"
														/>
														{!getValues("search") && (
															<div className="absolute right-2 top-0 h-full flex items-center">
																<AiOutlineSearch size={22} color="#FFFFFF" />
															</div>
														)}
														{fieldState.isDirty && (
															<div
																className="absolute cursor-pointer right-2 top-0 h-full flex items-center"
																onClick={() => {
																	resetField("search");
																	search();
																}}
															>
																<AiOutlineClose size={22} color="#FFFFFF" />
															</div>
														)}
													</>
												)}
											/>
										</div>
										<Button type="submit" className="w-64 bg-purple">
											Filtrar
										</Button>
									</div>
								</form>
							</div>
						</div>

						<div>
							<H1 className="text-jeans">conecte-se</H1>
							{loading ? renderLoading() : renderList()}
						</div>
					</div>
				</div>
			</div>
			{/* <Decoration
				image="dashboard"
				className="hidden md:block none absolute w-1/4 h-auto top-0 left-0"
			/> */}
		</>
	);
}
