import { UIElementProps } from "types/prop-types";

const rocket = require("assets/images/rocket.png");
const register = require("assets/images/register.png");
const recover = require("assets/images/recover.png");
const dashboard = require("assets/images/computer-man.png");
const myNetwork = require("assets/images/backpack-girl.png");
const messages = require("assets/images/bored-man.png");
const profile = require("assets/images/motorcycle-girl.png");

type DecorationProps = UIElementProps & {
	image? : "rocket" | "register" | "recover" | "dashboard" | "profile" | "myNetwork" | "messages";
};

const source = {
	rocket,
	register,
	recover,
	dashboard,
	profile,
	myNetwork,
	messages
}

export default function Decoration(props:DecorationProps){
	return <img src={source[props.image || "rocket"]} alt="Nuvem" {...props} className={`w-full h-full pointer-events-none object-contain ${props.className}`}  />
}
