import { useNetworking } from "context/NetworkingContext";
import { Link, NavLink } from "react-router-dom";

const navLinks = [
	{ title: "pessoas", route: "/" },
	{ title: "minha rede", route: "/minha-rede", counter: "invite" },
	{ title: "mensagens", route: "/mensagens", counter: "message" },
];

export default function Menu() {

	const { inviteCounter, messagesCounter } = useNetworking();

	function getCounter(counter?:string){
		if(!counter) return 0;
		switch(counter){
			case "invite":
				return inviteCounter || 0;
			case "message":
				return messagesCounter || 0;
			default:
				return 0;
		}
	}

	return (
		<div className="flex justify-center py-6 pb-4 md:py-10 space-x-5 md:space-x-12 md:text-2xl">
			{navLinks.map((item, index) => (
				<div key={item.title} className="relative">
						{getCounter(item.counter) > 0 && <div className="absolute -mb-2 bottom-full -right-6 bg-red rounded-full min-w-[28px] flex items-center justify-center leading-none text-center p-1 font-bold text-white text-sm">{getCounter(item.counter)}</div>}
				<NavLink
					id={`navitem-${index}`}
					className={({ isActive }) =>
						`${isActive ? "text-black active-border" : 'text-purple'}`
					}
					to={item.route}
				>
					{item.title}
				</NavLink>
				</div>
			))}
		</div>
	);
}
