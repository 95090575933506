import { H1 } from "components/typography";
import { useNetworking } from "context/NetworkingContext";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

export default function Banner() {
	const { eventActive } = useNetworking();
	const location = useLocation();

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	return (
		<div className="flex justify-center mt-5">
			{(eventActive?.image?.original?.url ||
				(isTabletOrMobile && eventActive?.imageMobile?.original?.url)) &&
			location.pathname === "/" ? (
				<picture className="overflow-hidden rounded-lg bg-white shadow-md p-0 w-[90%] md:w-[70%] h-[130px] md:h-[200px] md:h-60">
					<img
                    className="w-full h-full object-cover object-center"
						src={
							isTabletOrMobile
								? eventActive?.imageMobile?.original?.url
								: eventActive?.image?.original?.url
						}
						alt="Banner"
					/>
				</picture>
			) : (
				<H1 className="text-pink font-bold md:text-2xl">
					Evento: {eventActive?.name}
				</H1>
			)}
		</div>
	);
}
