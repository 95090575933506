import { api } from "./api";
import { fetchUser } from "./auth.service";

export async function uploadPicture(payload: any) {
	try {
		var form_data = new FormData();
		form_data.append("file", new File([DataURIToBlob(payload)], "profile.jpg"));
		form_data.append(
			"extra",
			JSON.stringify({
				session: "profile",
				thumbnails: [
					{ name: "small", width: 150, height: 225 },
					{ name: "medium", width: 300, height: 450 },
				],
			})
		);

		const { data } = await api.post("upload/file", form_data);
		return data.status === "OK" ? data.uploads[0] : data;
	} catch (err) {
		console.log("uploadPicture", err);
	}
}

export async function updateUserProfile(payload: any) {
	let player = await fetchUser();
	player = { _id: player._id, ...payload };
	const { data } = await api.put("player", player);
	return data;
}

export async function updateUserStatus() {
	const { data } = await api.put("player/me/status");
	let player = await fetchUser();
	return player;
}

export async function getLevel() {
	const { data } = await api.get("level");
	return data;
}

export async function deleteMyAccount(player: string) {
	const { data } = await api.delete(`/database/player?q=_id:'${player}'`);
	return data;
}

export async function getBoosters(player: string) {
	const { data } = await api.get(
		`database/fittrac_booster_log__c?q=player:'${player}'`
	);

	if (!data) return {};

	return data.reduce((acc: any, item: any) => {
		const booster = { [item.booster]: { start: item.start, end: item.end } };
		acc = { ...acc, ...booster };
		return acc;
	}, {});
}

export function getPlayer(id:string) {
	return api
		.get(`/player/${id}/status`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			console.log(error);
		});
}

function DataURIToBlob(dataURI: string) {
	const splitDataURI = dataURI.split(",");
	const byteString =
		splitDataURI[0].indexOf("base64") >= 0
			? atob(splitDataURI[1])
			: decodeURI(splitDataURI[1]);
	const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

	return new Blob([ia], { type: mimeString });
}
