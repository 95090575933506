export default function CardLoading() {
	return (
		<div className="rounded-lg bg-white border border-gray-200 shadow-xl">
			<div className="space-y-4 w-full h-64 p-4 animate-pulse">
			<div className="rounded-full bg-gray-300 p-2 mb-2 w-16 h-16 animate-pulse"></div>
				<div className="rounded bg-gray-300 p-2 mb-2 w-3/4 animate-pulse"></div>
				<div className="rounded bg-gray-300 p-1 w-full animate-pulse"></div>
				<div className="rounded bg-gray-300 p-1 w-full animate-pulse"></div>
				<div className="rounded bg-gray-300 p-1 w-full animate-pulse"></div>
			</div>
		</div>
	);
}
