import { useState } from 'react'

export function useLocalStorage(key: string, initialValue: string) {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key)
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue
		} catch (error) {
			// If error also return initialValue
			console.log(error)
			return initialValue
		}
	})
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: any) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore =
				value instanceof Function ? value(storedValue) : value
			// Save state
			setStoredValue(valueToStore)
			// Save to local storage
			window.localStorage.setItem(key, JSON.stringify(valueToStore))
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error)
		}
	}
	return [storedValue, setValue]
}

export function setStorageItem(key: string, valueToStore: string) {
	window.localStorage.setItem(
		key,
		typeof valueToStore === 'string'
			? valueToStore
			: JSON.stringify(valueToStore)
	)
}

export function getStorageItem(key: string) {
	if (typeof window !== 'undefined') {
		const item = window.localStorage.getItem(key)
		try {
			return item ? JSON.parse(item) : null
		} catch (error) {
			// If error also return initialValue
			// console.log(error)
			return item
		}
	}
	return null
}

export function removeStorageItem(key: string) {
	try {
		// Get from local storage by key
		window.localStorage.removeItem(key)
	} catch (error) {
		// If error also return initialValue
		console.log(error)
	}
}

export function clearStorage() {
	try {
		// Get from local storage by key
		window.localStorage.clear();
		window.sessionStorage.clear();
	} catch (error) {
		// If error also return initialValue
		console.log(error)
	}
}
