import Button from "components/ui/Button";
import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { useNetworking } from "context/NetworkingContext";
import { useWebsocket } from "context/WebsocketContext";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IUserCard } from "types/networking";
import { UIElementProps } from "types/prop-types";

import "./styles.scss";

const thumb1 = require("assets/images/thumb-placeholder-1.png");
const thumb2 = require("assets/images/thumb-placeholder-2.png");
const thumb3 = require("assets/images/thumb-placeholder-3.png");
const thumb4 = require("assets/images/thumb-placeholder-4.png");

const thumbs = [thumb1, thumb2, thumb3, thumb4];

type UserCardProps = UIElementProps & {
	data: IUserCard;
	preview?: boolean;
	onTagClick?: (tag: string) => void;
};

function getRandomThumb() {
	return thumbs[Math.floor(Math.random() * thumbs.length)];
}

export default function UserCard(props: UserCardProps) {
	const { data, preview } = props;
	const { user } = useAuth();
	const { myNetwork, eventActive }  = useNetworking();
	const { showModal } = useModal();
	const { sendMessage } = useWebsocket();
	const [isContact, setIsContact] = useState(false);
	const [hasPendingInvite, setHasPendingInvite] = useState(false);
	const navigate = useNavigate();

	function handleVisit() {
		navigate("/perfil/" + data._id);
	}

	function handleConnect() {
		showModal("send_invite", { invite: {...data, event: eventActive?._id} });
		// sendMessage({type:"connect", to: data._id});
	}

	function handleTagClick(item: string) {
		if (props.onTagClick) {
			props.onTagClick(item);
		}
	}

	useEffect(() => {

		if (myNetwork) {
			setIsContact(
				myNetwork.some(
					({ target, sender, status }: any) =>
						status === "accepted" &&
						(sender._id === data._id || target._id === data._id)
				)
			);
			setHasPendingInvite(
				myNetwork.some(
					({ target, sender, status }: any) =>
						status === "pending" &&
						(sender._id === data._id || target._id === data._id)
				)
			);
		}
	}, [myNetwork]);

	return (
		<div className="min-h-[250px] relative flex flex-col rounded-lg bg-white border border-gray-200 shadow-xl px-5 pb-5 leading-none space-y-5 overflow-hidden">
			<div
				className="absolute w-16 h-16 top-0 left-0"
				style={{ background: data.color || "#ccc" }}
			></div>
			<div className="flex flex-row items-start space-x-3 relative z-10">
				<div className="rounded-full w-16 h-16 overflow-hidden flex-0 bg-blue">
					<img
						src={data?.image ? data?.image.medium.url : getRandomThumb()}
						alt=""
						className="object-cover object-center w-full h-full"
					/>
				</div>
				<div className="flex-1">
					<h1 className="text-purple-dark font-bold text-lg leading-none mb-1">
						{data.name}
					</h1>
					<p className="text-purple">
						{data.position} - {data.company}
					</p>
					<p className="text-gray-500 text-sm pt-1">
						{data.city} - {data.state}
					</p>
				</div>
			</div>
			{!!data.bio && <div className="flex flex-row">
				<div className="text-gray-500 w-16">
					<em>Sobre</em>
				</div>
				<div className="flex-1 ml-2 text-purple">
					<em>
						<span className="font-bold">"</span>
						{data.bio}
						<span className="font-bold">"</span>
					</em>
				</div>
			</div>}
			{/* <div className="flex flex-row">
				<div className="text-gray-500 w-16">
					<em>Contato</em>
				</div>
				<div className="flex-1 ml-2 text-gray-700">
					<div className="font-bold mb-1">{data.phone}</div>
					<div>{data.email}</div>
				</div>
			</div> */}
			{!!data?.keywords && data?.keywords.length > 0 && <div className="flex flex-col">
				<div className="text-gray-500 mb-3">
					<em>Interesses</em>
				</div>
				<div className="flex-1 flex flex-row flex-wrap">
					{data?.keywords?.map((item) => (
						<p
							key={item}
							onClick={() => handleTagClick(item)}
							className="cursor-pointer bg-purple hover:bg-jeans-dark mr-1 mb-1 text-md text-white px-3 py-1 flex-0 flex flex-row items-center rounded-full"
						>
							{item}
						</p>
					))}
				</div>
			</div>}
			{preview && (
				<div className="flex-1 flex flex-row items-end justify-between space-x-2 py-2">
					<Button
						onClick={handleVisit}
						className="flex-1 p-1 bg-transparent text-yellow border-2 border-yellow whitespace-nowrap"
					>
						Ver perfil
					</Button>
				</div>
			)}
			{!preview && user && data._id != user?._id && (
				<div className="flex-1 flex flex-row items-end justify-between space-x-2 py-2">
					<Button
						onClick={handleVisit}
						className="flex-1 p-1 bg-transparent text-yellow border-2 border-yellow whitespace-nowrap"
					>
						Ver perfil
					</Button>
					{!isContact && !hasPendingInvite && (
						<Button
							onClick={handleConnect}
							className="flex-1 p-1 border-2 border-yellow whitespace-nowrap"
						>
							Trocar Cartão
						</Button>
					)}

					{!isContact && hasPendingInvite && (
						<Button
							color="danger"
							onClick={() => navigate("minha-rede")}
							className="flex-1 p-1 border-2 border-red whitespace-nowrap"
						>
							Convite pendente
						</Button>
					)}
				</div>
			)}
		</div>
	);
}
