import { IEvent } from "types/event";
import { IMeetingRoom, IUserCard } from "types/networking";
import { api } from "./api";

export async function listCategories(event: any) {
	try {
		const payload = [

		];
		const result = await api.post(`database/category__c/aggregate`, []);
		return result.data;
	} catch (e) {
		throw e;
	}
}

export async function fetchNetworkingCard(id: string) {
	try {
		const payload = [
			{
				$match: {
					_id: id,
				},
			},
		];
		const result = await api.post(
			`database/networking_card__c/aggregate`,
			payload
		);
		return Array.isArray(result.data) ? result.data[0] : result.data;
	} catch (e) {
		throw e;
	}
}

export async function saveNetworkingCard(payload: IUserCard) {
	try {
		const result = await api.put(`database/networking_card__c`, payload);
		return result.data;
	} catch (e) {
		throw e;
	}
}

export interface INetworkFind {
	event?: string;
	search?: string;
	category?: string;
	sub_categories?: string;
}

export async function searchNetworking(params?: INetworkFind) {
	try {
		let match: any = {
			search: params?.search || "",
			categories: params?.category ? [params?.category] : null,
			sub_categories: params?.sub_categories ? [params?.sub_categories] : null,
		};
		const result = await api.post(`find/networking`, match);
		return result.data as IUserCard[];
	} catch (e) {
		throw e;
	}
}

export async function searchMyNetwork(id: string, event?: string) {
	try {
		// console.log("searchMyNetwork", id, event);
		const payload = {id,event};
		const result = await api.post(`find/connections`, payload);
		return result.data;
	} catch (e) {
		throw e;
	}
}

export async function checkConnectionStatus(user: any, profile: any) {
	try {
		const payload = [
			{
					"$match": {
							"$or": [
									{
											"sender": user,
											"target": profile
									},
									{
											"sender": profile,
											"target": user
									}
							],
							"status" : "accepted"
					}
			}
	]
		const result = await api.post(`database/my_network__c/aggregate`, payload);
		return Array.isArray(result.data) && result.data.length > 0 ? true : false;
	} catch (e) {
		throw e;
	}
}

export async function sendConnectionRequest(payload: any) {
	try {
		const result = await api.post(`database/my_network__c`, {
			...payload,
			created: new Date(),
		});
		return result.data;
	} catch (e) {
		throw e;
	}
}

export async function acceptConnectionRequest(payload: any) {
	try {
		const result = await api.put(`database/my_network__c`, {
			...payload,
			updated: new Date(),
		});
		return result.data;
	} catch (e) {
		throw e;
	}
}

export async function rejectConnectionRequest(payload: any) {
	try {
		const result = await api.put(`database/my_network__c`, {
			...payload,
			updated: new Date(),
		});
		return result.data;
	} catch (e) {
		throw e;
	}
}

export async function sendConversationMessage(payload: any) {
	try {
		const result = await api.post(`database/message__c`, {
			...payload,
			created: new Date(),
		});
		return result.data;
	} catch (e) {
		throw e;
	}
}

export async function fetchConversation(members: any[]) {
	try {
		const payload = [
			{
				$match: {
					members: {
						$all: members,
					},
				},
			},
			{
				$sort: {
					created: -1,
				},
			},
		];
		const result = await api.post(`database/message__c/aggregate`, payload);
		return result.data;
	} catch (e) {
		throw e;
	}
}

export async function fetchEvent(user: string) {
	try {
		const result = await api.post<IEvent[]>(`find/events`, { user });
		return result.data;
	} catch (e) {
		throw e;
	}
}

export async function fetchMeetingRoom(participants: any[]) {
	try {
		const result = await api.post<IMeetingRoom>(`find/meeting_room`, { participants });
		return Array.isArray(result.data) && result.data.length > 0 ? result.data[0] : null;
	} catch (e) {
		throw e;
	}
}

export async function createMeetingRoom(title:string, participants:any[]) {
	try {
		// console.log("create meeting",title,participants);
		// return;
		const result = await api.put<IMeetingRoom>(`database/meeting_room__c`, { title, participants });
		return result.data;
	} catch (e) {
		throw e;
	}
}
