import User from "components/User";
import Logo from "./Logo";
import LogoSebrae from "./LogoSebrae";
import Menu from "./Menu";

export default function HeaderEvent() {
	return (
		<>
			<div className="bg-purple rounded-bl-xl">
				<div className="flex flex-col items-center text-xl md:text-2xl pb-12 md:pb-16 md:py-16">
					<LogoSebrae className="w-24 md:w-40 pb-10 pt-6 md:pt-0" />
					{/* <DecorationTopRight className="absolute w-32 md:w-56 top-0 right-0" /> */}
					<div className="font-bold text-white text-xl md:text-3xl text-center">
						Escolha qual evento deseja <br /> participar para o seu
					</div>
					<Logo className="w-1/2 md:w-96" />
				</div>
				<div className="absolute top-5 right-10">
					<User />
				</div>
			</div>
		</>
	);
}
