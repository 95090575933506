import React, { useEffect, useState } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";
import ParticipantView from "../ParticipantView";

export default function MeetingGrid(props: any) {
	const { join } = useMeeting();

	const { participants } = useMeeting();

	const joinMeeting = () => {
		join();
	};

	useEffect(() => {
		setTimeout(() => {
			joinMeeting();
		}, 1000);
	}, []);

	const chunk = (arr: any) => {
		const newArr = [];
		while (arr.length) newArr.push(arr.splice(0, 3));
		return newArr;
	};

	return (
		<div className="w-full h-full">
			{chunk([...participants.keys()]).map((k) => (
				<div className="w-full h-full flex justify-center" key={k}>
					{k.map((l: any, index:number) => (
						<ParticipantView key={l} participantId={l} index={index} />
					))}
				</div>
			))}
		</div>
	);
}
