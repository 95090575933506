import CardLoading from "components/loading/CardLoading";
import { H1 } from "components/typography";
import Decoration from "components/ui/Decoration";
import Header from "components/ui/Header";
import { useAuth } from "context/AuthContext";
import { useWebsocket } from "context/WebsocketContext";
import { formatDistance } from "date-fns";
import { ca, ptBR } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { RiArrowLeftSLine } from "react-icons/ri";
import {
	createMeetingRoom,
	fetchConversation,
	fetchMeetingRoom,
	sendConversationMessage,
} from "services/networking.service";
import copy from "copy-to-clipboard";

import useEvent from "utils/use-event";
import { getToken, createMeeting } from "services/video.service";
import ReactModal from "react-modal";

const messageMan = require("assets/images/messages-man.png");
import arrowRight from "../../assets/images/arrow-right.svg";
import maxmizeClose from "../../assets/images/maximize-close.svg";
import maxmizeOpen from "../../assets/images/maximize-open.svg";
import callGirl from "../../assets/images/call-girl.svg";
import { useCall } from "context/CallContext";
import Video from "components/Video";
import { useParams } from "react-router-dom";
import { useNetworking } from "context/NetworkingContext";
import { IoClose } from "react-icons/io5";
import { BiCopy } from "react-icons/bi";
import { actionLog } from "services/actionlog.service";

export function Messages() {
	useEvent("websocket-message", handleMessage);

	async function handleMessage({ detail }: CustomEvent) {
		try {
			if (detail.type === "message") {
				loadMessages();
			} else if (detail.type === "callAccepted") {
				setCall("meet");
			}
		} catch (e) {
			console.log(e);
		}
	}

	const { sendMessage } = useWebsocket();
	const { handleSubmit, control, resetField } = useForm({
		defaultValues: {
			message: "",
		},
	});

	const { user, refreshUser } = useAuth();
	const { eventActive, myNetwork, loadMyNetwork, updateMessagesCounter } =
		useNetworking();
	const params = useParams();
	const { recivedCall, call, setCall, maxmized, setMaxmized, setCallUser } =
		useCall();
	const [loading, setLoading] = useState(true);
	const [token, setToken] = useState("");
	const [meetingId, setMeetingId] = useState<string | undefined>("");
	const [conversationList, setConversationList] = useState<any[]>([]);
	const [conversation, setConversation] = useState<any>();
	const [messages, setMessages] = useState<any[]>();

	const [modalIsOpen, setIsOpen] = useState(false);
	const [linkMeta, setLinkMeta] = useState("");
	const [copied, setCopied] = useState(false);

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	async function handleLoadConversation(item: any) {
		if (loading || (conversation?._id && item._id === conversation._id)) return;

		// setLoading(true);

		try {
			setMessages(undefined);
			setConversation(item);
		} catch (e) {
			setMessages(undefined);
			// setLoading(false);
		}
	}

	async function loadMessages() {
		const result = await fetchConversation([conversation._id, user?._id]);
		setMessages(result);

		setTimeout(() => {
			let objDiv = document.getElementById("messages-container");
			if (objDiv) {
				objDiv.scrollTop = objDiv.scrollHeight || 0;
			}
		}, 100);
	}

	useEffect(() => {
		if (!params.id) {
			runCall();
		} else {
			const conversationItem = conversationList.find(
				(item) => item._id === params.id
			);

			handleLoadConversation(conversationItem);
		}
	}, [recivedCall, conversationList, params]);

	async function runCall() {
		if (recivedCall) {
			setMeetingId(undefined);
			const token = await getToken();

			const conversationItem = conversationList.find(
				(item) => item._id === recivedCall.player
			);

			handleLoadConversation(conversationItem);
			setToken(token);
			setMeetingId(recivedCall.meet);

			setCallUser(recivedCall.player);
			setCall("meet");
		}
	}

	useEffect(() => {
		if (!conversation) return;
		loadMessages();
		// setLoading(false);
	}, [conversation]);

	async function submitMessage(data: any) {
		try {
			if (!data || !data.message.trim()) return;

			const result = await sendConversationMessage({
				members: [conversation._id, user?._id],
				message: data.message,
				sender: user?._id,
			});

			if (result) {
				sendMessage({
					type: "message",
					request: result._id,
					from: user?._id,
					to: conversation._id,
					message: data.message,
				});
			}

			resetField("message");
			loadMessages();
		} catch (e) {
			console.log(e);
		}
	}

	const handleActionLog = (type: any, user: any, from: any) => {
		actionLog("interaction", {
			type: type,
			sender: user,
			from: from,
		});
	};

	async function handleMeetingRoom() {
		try {
			let participants = [conversation._id, user?._id];
			const meetingName = `Reunião de ${conversation.name.split(" ")[0]} e ${
				user?.name.split(" ")[0]
			}`;

			let meetingRoom = await fetchMeetingRoom(participants);

			if (!meetingRoom) {
				let participants = [{ _id: conversation._id }, { _id: user?._id }];
				meetingRoom = await createMeetingRoom(meetingName, participants);
			}

			setLinkMeta(meetingRoom.url);
			handleActionLog(
				"reuniao_metaverso_networking",
				user?._id,
				conversation._id
			);
		} catch (e) {}
	}

	async function handleCall() {
		setMeetingId(undefined);
		setCall("call");

		const token = await getToken();
		const meetingIdItem = await createMeeting({ token });

		setToken(token);
		setMeetingId(meetingIdItem);
		setCallUser(conversation._id);

		sendMessage({
			type: "call",
			request: meetingIdItem,
			from: user?._id,
			to: conversation._id,
		});
	}

	function renderMessages() {
		if (!messages) return;
		return messages
			.map((item: any, index: any) => {
				return (
					<div
						key={item._id}
						className={`${
							item.sender === user?._id
								? "bg-white self-end"
								: "bg-yellow-light self-start"
						} min-h-40 min-w-[200px] max-w-1/2 rounded-lg shadow relative p-3`}
					>
						<div>{item.message}</div>
						<div className="absolute -bottom-4 right-3 text-xs text-white mt-1">
							{formatDistance(new Date(item.created), new Date(), {
								addSuffix: true,
								locale: ptBR,
							})}
						</div>
					</div>
				);
			})
			.reverse();
	}

	function renderLoading() {
		return (
			<div className="grid md:grid-cols-3 xl:grid-cols-4 gap-6">
				<CardLoading />
				<CardLoading />
				<CardLoading />
				<CardLoading />
			</div>
		);
	}

	function renderEmptyList() {
		return (
			<div className="text-center">
				<H1>Nenhum resultado encontrado</H1>
			</div>
		);
	}

	const copyToClipboard = (link: any) => {
		copy(link);
		setCopied(true);
	};

	function linkMetaModal() {
		return (
			<ReactModal
				isOpen={modalIsOpen}
				onAfterOpen={() => handleMeetingRoom()}
				contentElement={() => (
					<div className="shadow-xl py-3 bg-blue rounded-lg max-w-[800px] max-h-[200px] flex flex-col justify-between absolute top-0 left-0 right-0 bottom-0 m-auto">
						<button
							className="absolute top-3 right-3 bg-blue-light rounded-lg text-blue"
							onClick={() => setIsOpen(false)}
						>
							<IoClose size={28} />
						</button>
						<div className="text-center">
							<p className="text-white font-bold text-lg">
								Sala de Reunião no Metaverso!
							</p>
							<p className="text-blue-light ">
								Clique para abrir ou copie o link para compartilhar.
							</p>
						</div>
						<div className="flex flex-row p-3 space-x-3">
							{!linkMeta ? (
								<div className="text-center rounded-md bg-blue-light flex-1">
									<div className="p-3">
										Aguarde, estamos configurando sua sala...
									</div>
								</div>
							) : null}
							{linkMeta ? (
								<div className="text-center rounded-md bg-blue-light flex-1">
									<a
										href={linkMeta}
										target={"_blank"}
										className="break-all block h-full w-full text-black drop-shadow-lg flex flex-row items-center justify-center"
									>
										{linkMeta}
									</a>
								</div>
							) : null}
							{linkMeta ? (
								<button
									className="rounded-md bg-white text-blue p-2 font-bold"
									onClick={() => copyToClipboard(linkMeta)}
								>
									<div className="flex flex-row items-center space-x-2">
										<span>
											<BiCopy />
										</span>
										<span>Copiar Link</span>
									</div>
								</button>
							) : null}
						</div>
						<div>
							{copied ? (
								<div className="px-3">
									<p className="p-2 bg-yellow font-bold text-white text-center rounded-lg">
										Link copiado com sucesso
									</p>
								</div>
							) : null}
						</div>
					</div>
				)}
			></ReactModal>
		);
	}

	function renderList() {
		if (conversationList.length === 0) {
			return renderEmptyList();
		}

		return (
			<div className="min-h-[500px] md:flex flex-row">
				{!maxmized && (
					<div
						className={`${
							conversation && conversation.name && isTabletOrMobile
								? "hidden"
								: ""
						}  w-full md:w-96 space-y-2 md:px-2`}
					>
						<H1 className="text-purple ml-4 md:ml-0">
							minhas conversas ({conversationList.length})
						</H1>
						{conversationList.map((item, index) => {
							return (
								<div
									key={item._id}
									onClick={() => handleLoadConversation(item)}
									className="bg-purple p-2 hover:ring-2 hover:cursor-pointer flex flex-row items-center space-x-3"
								>
									<div className="w-12 h-12 rounded-full overflow-hidden bg-blue flex flex-item items-center justify-center text-white">
										{item.image && (
											<img
												src={item.image.medium.url}
												alt=""
												className="w-full h-full object-cover object-center"
											/>
										)}
										{!item.image && (
											<div className="font-bold text-2xl">
												{item.name.substring(0, 1)}
											</div>
										)}
									</div>
									<div className="flex-1 text-white">{item.name}</div>
								</div>
							);
						})}
					</div>
				)}

				<div
					className={`${
						!conversation || !conversation.name ? "hidden md:flex" : ""
					} ${
						isTabletOrMobile
							? "fixed w-full h-full md:h-screen top-0 lef-0"
							: "relative"
					}  flex-1 flex flex-col`}
				>
					{conversation && conversation.name && (
						<div
							className={`${
								isTabletOrMobile && call ? "hidden" : "flex"
							} bg-pink py-4 px-5 border-b border-solid border-white  items-center justify-between`}
						>
							<div className="flex items-center">
								{maxmized && (
									<button className="-ml-4" onClick={() => setMaxmized(false)}>
										<RiArrowLeftSLine color="white" size={40} />
									</button>
								)}

								<button
									className="-ml-4 md:hidden"
									onClick={() => {
										setMessages(undefined);
										setConversation("");
									}}
								>
									<RiArrowLeftSLine color="white" size={30} />
								</button>

								<div className="w-12 h-12 rounded-full overflow-hidden bg-yellow flex flex-item items-center justify-center text-white">
									{conversation.image && (
										<img
											src={conversation.image.medium.url}
											alt=""
											className="w-full h-full object-cover object-center"
										/>
									)}
									{!conversation.image && (
										<div className="font-bold text-2xl">
											{conversation.name.substring(0, 1)}
										</div>
									)}
								</div>
								<p className="ml-4 text-white text-lg">{conversation.name}</p>
							</div>

							{!call && (
								<ul className="list-none flex">
									<li className="md:block">
										<button
											className="animate-wiggle font-bold bg-white mr-2 ring-1 ring-white border-white rounded-md w-full h-8 flex items-center justify-center"
											onClick={() => setIsOpen(true)}
										>
											<p className="text-blue">Reunião no Metaverso</p>
										</button>
									</li>
									<li className="hidden md:block">
										<button
											className="ml-2 border border-solid border-white rounded-md w-10 h-8 flex items-center justify-center"
											onClick={() => setMaxmized(!maxmized)}
										>
											<img
												className="h-5"
												src={maxmized ? maxmizeOpen : maxmizeClose}
												alt="Maxmizar"
											/>
										</button>
									</li>
									{linkMetaModal()}
									{/* <li>
										<button
											onClick={() => handleCall()}
											className="mr-2 border border-solid border-white rounded-md w-10 h-8 flex items-center justify-center"
										>
											<img
												className="h-5 -mb-1"
												src={phone}
												alt="ligar normal"
											/>
										</button>
									</li>
									 <li>
										<button
											onClick={() => handleCall()}
											className="border border-solid border-white rounded-md w-10 h-8 flex items-center justify-center"
										>
											<img
												className="h-6 -mb-1"
												src={camera}
												alt="Ligar video"
											/>
										</button>
									</li> */}
								</ul>
							)}
						</div>
					)}

					{!conversation && (
						<div>
							<H1 className="text-purple">Nenhuma conversa selecionada</H1>
						</div>
					)}

					{messages && messages.length > 0 && !call && (
						<div
							id="messages-container"
							className="bg-pink w-full h-full md:h-[400px] p-5 overflow-y-auto"
						>
							<div className="flex flex-col space-y-7 justify-end">
								{renderMessages()}
							</div>
						</div>
					)}

					{(!messages || messages.length === 0) && !call && (
						<div className=" bg-pink  w-full flex-1 flex flex-items items-center justify-center">
							<img src={messageMan} alt="message" />
						</div>
					)}

					{call === "call" && (
						<div className="bg-pink w-full flex-1 flex flex-col flex-items items-center justify-center">
							<div className="md:hidden mt-10 w-12 h-12 rounded-full overflow-hidden bg-yellow flex flex-item items-center justify-center text-white">
								{conversation.image && (
									<img
										src={conversation.image.url}
										alt=""
										className="w-full h-full object-cover object-center"
									/>
								)}
								{!conversation.image && (
									<div className="font-bold text-2xl">
										{conversation.name.substring(0, 1)}
									</div>
								)}
							</div>
							<p className="md:hidden text-white text-xl mt-3">
								{conversation.name}
							</p>
							<H1 className="text-white md:mt-4 text-lg md:text-xl">
								Chamando
							</H1>
							<img src={callGirl} alt="Call" />
						</div>
					)}

					{call && token && (
						<div className="bg-pink w-full flex-1 flex flex-col flex-items items-center justify-center">
							{meetingId && <Video meet={meetingId} token={token} />}
						</div>
					)}

					{conversation && !call && (
						<div className="bg-pink flex-0 border-t border-solid border-white">
							<form
								onSubmit={handleSubmit(submitMessage)}
								className=" flex flex-row p-3 space-x-4 "
							>
								<div className="relative w-full">
									<Controller
										name="message"
										control={control}
										render={({ field }) => (
											<input
												{...field}
												placeholder="Digite sua mensagem"
												className="flex-1 pl-3 pr-12 py-0 border-0 rounded-full bg-white  text-purple w-full h-10 placeholder-purple placeholder:text-sm "
											/>
										)}
									/>
									<button className="absolute right-4 top-2 ">
										<img src={arrowRight} alt="Seta para direita" />
									</button>
								</div>
							</form>
						</div>
					)}
				</div>
			</div>
		);
	}

	useEffect(() => {
		scrollTo({ top: 0 });

		updateMessagesCounter(0, true);

		// console.log(user, eventActive);

		if (user) {
			loadMyNetwork();
		}
	}, [user, eventActive]);

	useEffect(() => {
		// console.log(myNetwork);

		if (myNetwork) {
			loadData();
		}
	}, [myNetwork]);

	async function loadData() {
		if (!user || !myNetwork) {
			return;
		}

		try {
			setLoading(true);
			const acceptedResult = myNetwork
				.filter(({ _id, status }: any) => status === "accepted")
				.map(({ _id, sender, target }: any) => {
					if (sender._id === user._id) {
						return {
							_id: target._id,
							name: target.name,
							image: target.image,
						};
					} else {
						return {
							_id: sender._id,
							name: sender.name,
							image: sender.image,
						};
					}
				});

			const conversarionResult: any[] = acceptedResult;
			setConversationList(conversarionResult);

			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<>
			<div className="pb-10">
				<Header />
				<div className="md:px-5 mt-5">
					<div className="mx-auto 2xl:w-[1380px] space-y-8">
						<div>{loading ? renderLoading() : renderList()}</div>
					</div>
				</div>
			</div>
			{/* <Decoration
				image="messages"
				className="hidden md:block absolute md:w-1/4 h-auto top-0 left-0"
			/> */}
		</>
	);
}
