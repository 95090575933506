import { H1 } from "components/typography";
import Button from "components/ui/Button";
import UserCard from "components/user-card";
import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { useWebsocket } from "context/WebsocketContext";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiCheckCircle } from "react-icons/bi";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { actionLog } from "services/actionlog.service";
import { sendConnectionRequest } from "services/networking.service";

export function SendInviteModal(props: any, onAnswer?: Function) {
	const modalID = "send_invite";
	const [modalIsOpen, setIsOpen] = useState(false);
	const [inviteSent, setInviteSent] = useState(false);
	const { children } = props;
	const [invite, setInvite] = useState<any>();
	const { showModal, closeModal, openModal, modalData } = useModal();
	const { user } = useAuth();
	const { sendMessage } = useWebsocket();
	const navigate = useNavigate();

	async function handleAccept() {
		try {
			const inviteResult = await sendConnectionRequest({
				sender: user?._id,
				target: invite._id,
				status: "pending",
				event: invite.event,
			});
			if (inviteResult) {
				sendMessage({
					type: "connect",
					request: inviteResult._id,
					from: user?._id,
					to: invite._id,
					event: inviteResult.event,
				});
				setInviteSent(true);
				handleActionLog("troca_de_cartao_networking", user?._id, invite._id);
			}
		} catch (e) {
			console.log(e);
		}
	}

	const handleActionLog = (type: any, user: any, from: any) => {
		actionLog("interaction", {
			type: type,
			sender: user,
			from: from,
		});
	};

	function handleReject() {
		closeModal();
	}

	useEffect(() => {
		setInviteSent(false);
		setInvite(undefined);
		setIsOpen(openModal === modalID);
	}, [openModal]);

	useEffect(() => {
		if (modalData) {
			setInvite(modalData.invite);
		}
	}, [modalData]);

	ReactModal.setAppElement("#modal");

	return (
		<ReactModal
			shouldCloseOnOverlayClick={false}
			shouldCloseOnEsc={true}
			onRequestClose={closeModal}
			isOpen={modalIsOpen}
			contentLabel="Example Modal"
			portalClassName=""
			overlayClassName="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
			contentElement={() => (
				<div>
					<div className="relative bg-white rounded-lg p-3 flex flex-col space-y-6 flex-none mx-5">
						<button
							onClick={closeModal}
							className="absolute -top-6 -right-6 flex items-center justify-center bg-white rounded-full w-12 h-12 text-white bg-blue"
						>
							<AiOutlineClose size={32} />
						</button>
						{invite && (
							<div className="text-center leading-none">
								<H1 className="font-bold text-purple rounded-full">
									Você deseja trocar cartões com este usuário?
								</H1>
								<UserCard data={invite} preview={true} />
							</div>
						)}
						{inviteSent && (
							<div className="flex flex-row space-x-6 w-full justify-between p-3 pt-0">
								<div className="flex flex-1 space-x-3 flex-row items-center text-blue-500">
									<BiCheckCircle size={36} />
									<div className="flex-1 leading-none">
										Convite enviado
										<br className="hidden md:inline-block" /> com sucesso!
									</div>
								</div>
								<Button onClick={() => navigate("/minha-rede")}>
									VER CONVITES
								</Button>
							</div>
						)}
						{!inviteSent && (
							<div className="flex flex-row space-x-6 w-full justify-between p-3 pt-1">
								<Button
									className="flex-1"
									color="success"
									onClick={handleAccept}
								>
									ENVIAR CONVITE
								</Button>
								<Button
									className="flex-1"
									color="danger"
									onClick={handleReject}
								>
									CANCELAR
								</Button>
							</div>
						)}
					</div>
				</div>
			)}
		/>
	);
}
