import { H1 } from "components/typography";
import Button from "components/ui/Button";
import UserCard from "components/user-card";
import { useAuth } from "context/AuthContext";
import { ModalContext, useModal } from "context/ModalContext";
import { useNetworking } from "context/NetworkingContext";
import { useWebsocket } from "context/WebsocketContext";
import { useContext, useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineLoading } from "react-icons/ai";
import ReactModal from "react-modal";
import {
	acceptConnectionRequest,
	rejectConnectionRequest,
} from "services/networking.service";

export function InviteModal(props: any, onAnswer?: Function) {
	const modalID = "invite";
	const [modalIsOpen, setIsOpen] = useState(false);
	const { children } = props;
	const [invite, setInvite] = useState<any>();
	const [isSent, setIsSent] = useState<any>();
	const [inviteType, setInviteType] = useState("");
	const { showModal, closeModal, openModal, modalData } = useModal();
	const { sendMessage } = useWebsocket();
	const { user, refreshUser } = useAuth();

	async function handleAccept() {
		try {
			const inviteResult = await acceptConnectionRequest({
				_id: invite.request,
				sender: invite._id,
				target: user?._id,
				status: "accepted",
				event: invite.event
			});
			if (inviteResult) {
				sendMessage({
					type: "connect-accept",
					from: user?._id,
					to: invite._id,
					event: invite.event
				});
			}
			closeModal();
			refreshUser();
		} catch (e) {
			console.log(e);
		}
	}

	async function handleReject() {
		try {
			rejectConnectionRequest({
				_id: invite.request,
				sender: invite._id,
				target: user?._id,
				status: "rejected",
				event: invite.event
			});
			closeModal();
			refreshUser();
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		setIsOpen(openModal === modalID);
	}, [openModal]);

	useEffect(() => {
		console.log("modal issue",modalData);
		if (modalData) {
			setInvite(modalData.invite);
			setInviteType(
				modalData.inviteType ? modalData.inviteType.inviteType : ""
			);
			setIsSent(modalData.sent);
		}
	}, [modalData]);

	ReactModal.setAppElement("#modal");

	return (
		<ReactModal
			shouldCloseOnOverlayClick={false}
			shouldCloseOnEsc={true}
			onRequestClose={closeModal}
			isOpen={modalIsOpen}
			contentLabel="Example Modal"
			portalClassName=""
			overlayClassName="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
			contentElement={() => (
				<div>
					<div className="relative bg-white rounded-lg p-3 flex flex-col space-y-6 flex-none mx-5">
						<button
							onClick={closeModal}
							className="absolute -top-6 -right-6 flex items-center justify-center rounded-full w-12 h-12 text-white bg-blue"
						>
							<AiOutlineClose size={32} />
						</button>
						{invite && (
							<div className="text-center leading-none">
								<H1 className="font-bold text-purple rounded-full">
									{isSent
										? "Solicitação enviada"
										: "Você tem um novo pedido de conexão!"}
								</H1>
								<UserCard data={invite} preview={true} />
							</div>
						)}
						<div className="flex flex-row space-x-6 w-full justify-between p-3">
							<>
								{isSent ? (
									""
								) : (
									<Button
										className="flex-1"
										color="outline"
										onClick={handleAccept}
									>
										ACEITAR
									</Button>
								)}

								<Button
									className="flex-1"
									color="danger"
									onClick={handleReject}
								>
									{isSent ? "CANCELAR SOLICITAÇÃO" : "REJEITAR"}
								</Button>
							</>
						</div>
					</div>
				</div>
			)}
		/>
	);
}
