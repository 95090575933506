import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";
import { RouteList } from "./routes";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import { ModalProvider } from "context/ModalContext";
import ToastProvider from "context/ToastContext";
import { WebsocketProvider } from "context/WebsocketContext";
import { NetworkingProvider } from "context/NetworkingContext";
import { CallProvider } from "context/CallContext";

const App = () => (
	<BrowserRouter>
		<AuthProvider>
			<WebsocketProvider>
				<CallProvider>
					<ModalProvider>
						<NetworkingProvider>
							<RouteList />
							<ToastProvider />
						</NetworkingProvider>
					</ModalProvider>
				</CallProvider>
			</WebsocketProvider>
		</AuthProvider>
	</BrowserRouter>
);

export default App;
