import { useAuth } from "context/AuthContext";
import { useNetworking } from "context/NetworkingContext";
import { useEffect, useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Link } from "react-router-dom";
import { UIElementProps } from "types/prop-types";

const defaultAvatar = require("assets/images/avatar.jpg");

export default function User(props: UIElementProps) {
	const { user, signOut } = useAuth();
	const [isOpen, setIsOpen] = useState(false);
	const { setEventActive } = useNetworking();

	// useEffect(() => {
	// 	console.log(user);
	// }, [user]);

	return (
		<div>
			{/* <Link to="/profile"> */}
			<div className="relative flex flex-col items-center text-white">
				<Link to="/perfil">
					<div className="rounded-full w-10 h-10 md:w-20 md:h-20 bg-blue-dark overflow-hidden">
						<img
							src={user?.image?.medium?.url || defaultAvatar}
							alt="Avatar"
							className="w-full h-full object-cover object-center"
						/>
					</div>
				</Link>
				<p
					onClick={() => setIsOpen(!isOpen)}
					className="flex flex-row items-center justify-between md:mt-2 cursor-pointer hover:ring-2 ring-white pl-2 py-1 rounded-lg"
				>
					<strong>{user?.name ? user?.name.split(" ")[0] : "EU"}</strong>
					{isOpen ? <BiChevronUp size={24} /> : <BiChevronDown size={24} />}
				</p>
				{isOpen && (
					<div
						onMouseLeave={() => setIsOpen(false)}
						className="absolute bg-pink rounded-lg px-2 py-1 text-white w-36 text-center top-full mt-1"
					>
						<ul className="divide-y-2 text-center">
							<li>
								<Link
									to="/perfil"
									className="block hover:bg-orange-100 w-full rounded-md my-1"
								>
									Ver perfil
								</Link>
							</li>
							{/* <li
								onClick={() => {
									setEventActive(undefined);
									sessionStorage.removeItem("EventId");
								}}
							>
								<Link
									to="/"
									className="block hover:bg-orange-100 w-full rounded-md my-1"
								>
									Ir para outro evento
								</Link>
							</li> */}
							<li>
								<button
									onClick={() => {
										signOut();
										sessionStorage.removeItem("EventId");
									}}
									className="hover:bg-orange-100 w-full rounded-md my-1"
								>
									Sair
								</button>
							</li>
						</ul>
					</div>
				)}
			</div>
			{/* </Link> */}
		</div>
	);
}
