import CardLoading from "components/loading/CardLoading";
import { H1 } from "components/typography";
import Button from "components/ui/Button";
import Decoration from "components/ui/Decoration";
import Header from "components/ui/Header";
import UserCard from "components/user-card";
import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { useNetworking } from "context/NetworkingContext";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { IUserCard } from "types/networking";

export function MyNetwork() {
	const {
		register,
		handleSubmit,
		control,
		getValues,
		setValue,
		resetField,
		formState: { errors },
	} = useForm({
		defaultValues: {
			search: "",
		},
	});

	const { user } = useAuth();
	const { eventActive, myNetwork, loadMyNetwork } = useNetworking();
	const { showModal } = useModal();
	const [loading, setLoading] = useState(true);
	const [searchResult, setSearchResult] = useState<IUserCard[]>([]);
	const [pendingInvites, setPendingInvites] = useState<IUserCard[]>([]);
	const [receivedInvites, setReceivedInvites] = useState<IUserCard[]>([]);
	const [acceptedInvites, setAcceptedInvites] = useState<IUserCard[]>([]);
	const [inviteType, setInviteType] = useState<string>("pending");

	const navigate = useNavigate();

	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1024px)",
	});

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	async function onSubmit(data: any) {
		search(data);
	}

	async function search(data?: any) {
		try {
			const { search } = data;
			const filteredResult = acceptedInvites.filter((item) => {
				return (
					JSON.stringify(item).toLowerCase().indexOf(search.toLowerCase()) > -1
				);
			});
			setSearchResult(filteredResult);
		} catch (e) {
			setSearchResult(acceptedInvites);
		}
	}

	function handleInviteClick(invite: IUserCard) {
		showModal("invite", {
			invite,
			request: invite.request,
			inviteType: { inviteType },
			sent: invite.sent,
		});
	}

	function renderPendingList() {
		const dataSource =
			inviteType === "received" ? receivedInvites : pendingInvites;

		if (dataSource.length === 0) {
			return renderEmptyPendingList();
		}
		return (
			<div className="flex flex-row space-x-2 items-start justify-start pb-5">
				{dataSource.map((item, index) => {
					return (
						<div
							key={item._id}
							onClick={() => handleInviteClick(item)}
							className="flex-0 flex flex-col items-center space-y-3 text-center leading-none w-32 py-3 cursor-pointer hover:bg-blue hover:bg-opacity-20 rounded-lg"
						>
							<div className="rounded-full w-16 h-16 overflow-hidden flex-0 bg-purple flex flex-row items-center justify-center">
								{item.image && (
									<img
										src={item.image.medium.url}
										alt=""
										className="object-cover object-center w-full h-full"
									/>
								)}
								{!item.image && (
									<b className="text-3xl text-white">
										{item.name?.substring(0, 1).toUpperCase()}
									</b>
								)}
							</div>
							<span>{item.name}</span>
						</div>
					);
				})}
			</div>
		);
	}

	function renderList() {
		if (searchResult.length === 0) {
			return renderEmptyList();
		}
		return (
			<div className="grid md:grid-cols-3 xl:grid-cols-4 gap-6">
				{searchResult.map((item, index) => {
					return <UserCard key={index} data={item} />;
				})}
			</div>
		);
	}

	function renderEmptyList() {
		return (
			<div className="text-center">
				<H1>Nenhum resultado encontrado</H1>
			</div>
		);
	}

	function renderEmptyPendingList() {
		return (
			<div className="text-center p-4 border border-purple border-opacity-50 rounded-lg mb-2">
				<div>
					<div className="leading-none mb-3">
						Não existem convites pendentes no momento. Que tal buscar mais
						conexões?
					</div>
					<Button className="ml-3 bg-purple" onClick={() => navigate("/")}>
						<div className="flex flex-row flex-nowrap">
							Encontrar mais pessoas{" "}
							<AiOutlineSearch size={24} className="ml-2" />
						</div>
					</Button>
				</div>
			</div>
		);
	}

	function renderLoading() {
		return (
			<div className="grid md:grid-cols-3 xl:grid-cols-4 gap-6">
				<CardLoading />
				<CardLoading />
				<CardLoading />
				<CardLoading />
			</div>
		);
	}

	useEffect(() => {
		try {
			if (!eventActive || !user || !myNetwork) return;
			const pendingResult = myNetwork
				.filter(({ _id, status }: any) => status === "pending")
				.map(({ _id, sender, target, event }: any) => {
					if (sender._id === user._id) {
						return { ...target, sender: true, request: _id, sent: true, event };
					} else {
						return { ...sender, sent: false, request: _id, event };
					}
				});

			const acceptedResult = myNetwork
				.filter(({ _id, status }: any) => status === "accepted")
				.map(({ _id, sender, target, event }: any) => {
					if (sender._id === user._id) {
						return { ...target, sender: true, request: _id, event };
					} else {
						return { ...sender, request: _id, event };
					}
				});

			setPendingInvites(pendingResult);
			setAcceptedInvites(acceptedResult);
			setSearchResult(acceptedResult);
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	}, [user, myNetwork, eventActive]);

	useEffect(() => {
		scrollTo({ top: 0 });

		async function loadData() {
			try {
				setLoading(true);
				loadMyNetwork();
				setLoading(false);
			} catch (e) {
				setLoading(false);
			}
		}

		if (user && eventActive) {
			loadData();
		}
	}, [user, eventActive]);

	return (
		<>
			<div className="pb-10">
				<Header />
				<div className="px-5">
					<div className="mx-auto 2xl:w-[1380px] space-y-8">
						<div>
							<H1 className="text-jeans flex flex-row items-center">
								<span>convites pendentes ({pendingInvites.length})</span>
							</H1>
							<div>{renderPendingList()}</div>

							<H1 className="text-jeans">meus contatos</H1>
							<div>
								<form id="searchForm" onSubmit={handleSubmit(onSubmit)}>
									<div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-2">
										<div className="flex-1 relative">
											<Controller
												name="search"
												control={control}
												render={({ field, fieldState }) => (
													<>
														<input
															{...field}
															className="pl-3 pr-8 py-0 border-0 rounded-full bg-purple text-white w-full h-10 placeholder-white placeholder:text-sm"
															placeholder="Busca por nome, empresa ou palavras-chave"
														/>
														{!getValues("search") && (
															<div className="absolute right-2 top-0 h-full flex items-center">
																<AiOutlineSearch size={22} color="#FFFFFF" />
															</div>
														)}
														{fieldState.isDirty && (
															<div
																className="absolute cursor-pointer right-2 top-0 h-full flex items-center"
																onClick={() => {
																	resetField("search");
																	search();
																}}
															>
																<AiOutlineClose size={22} color="#FFFFFF" />
															</div>
														)}
													</>
												)}
											/>
										</div>
										<Button type="submit" className="w-64 bg-purple">
											Filtrar
										</Button>
									</div>
								</form>
							</div>
						</div>

						<div>{loading ? renderLoading() : renderList()}</div>
					</div>
				</div>
			</div>
			{/* <Decoration
				image="myNetwork"
				className="hidden md:block absolute md:w-1/4 h-auto top-0 left-0"
			/> */}
		</>
	);
}
