import React, { useEffect, useMemo, useRef } from "react";
import { useParticipant, useMeeting } from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import Controling from "../Controling";
import { useCall } from "context/CallContext";

export default function ParticipantView(props: any) {
	const micRef: any = useRef(null);
	const { webcamStream, micStream, webcamOn, micOn } = useParticipant(
		props.participantId
	);

	const { localParticipant } = useMeeting();
	const { call } = useCall();

	const isUser = localParticipant?.id === props.participantId;

	const videoStream = useMemo(() => {
		if (webcamOn) {
			try {
				const mediaStream = new MediaStream();
				mediaStream.addTrack(webcamStream.track);
				return mediaStream;
			} catch (error) {}
		}
	}, [webcamStream, webcamOn]);

	useEffect(() => {
		if (micRef.current) {
			try {
				if (micOn) {
					const mediaStream = new MediaStream();
					mediaStream.addTrack(micStream.track);

					micRef.current.srcObject = mediaStream;
					micRef.current
						.play()
						.catch((error: any) =>
							console.error("videoElem.current.play() failed", error)
						);
				} else {
					micRef.current.srcObject = null;
				}
			} catch (error) {}
		}
	}, [micStream, micOn]);

	return (
		<div key={props.participantId}>
			{isUser && <Controling id={props.participantId} />}
			{!isUser && micOn && micRef && <audio ref={micRef} autoPlay />}
			{webcamOn && (
				<div
					className={`${call !== 'meet' && 'hidden'} ${
						isUser
							? "absolute bottom-10 right-10 rounded-full h-40 w-40 overflow-hidden"
							: "w-full h-full"
					}`}
				>
					<ReactPlayer
						playsinline
						pip={false}
						light={false}
						controls={true}
						muted={true}
						playing={true}
						url={videoStream}
						height={"100%"}
						width={"100%"}
						onError={(err) => {
							console.log(err, "participant video error");
						}}
					/>
				</div>
			)}
		</div>
	);
}
