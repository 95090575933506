import { H1 } from "components/typography";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useAuth } from "context/AuthContext";

import Message from "../../assets/images/message-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useNetworking } from "context/NetworkingContext";

export function MessageModal() {
	const [showModal, setShowModal] = useState(true);
	const [showMessage, setShowMessage] = useState(false);
	const [conversationList, setConversationList] = useState<any[]>([]);

	const { user } = useAuth();
	const { myNetwork } = useNetworking();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		async function loadData() {
			if (!user || !myNetwork) return;

			try {
				const acceptedResult = myNetwork
					.filter(({ _id, status }: any) => status === "accepted")
					.map(({ _id, sender, target }: any) => {
						if (sender._id === user._id) {
							return {
								_id: target._id,
								name: target.name,
								image: target.image,
							};
						} else {
							return {
								_id: sender._id,
								name: sender.name,
								image: sender.image,
							};
						}
					});

				const conversarionResult: any[] = acceptedResult;
				setConversationList(conversarionResult);
				setShowModal(true);
			} catch (error) {
				console.log(error);
			}
		}

		scrollTo({ top: 0 });

		loadData();
	}, [user, myNetwork]);

	function handleLoadConversation(id: string) {
		navigate(`/mensagens/${id}`);
		setShowMessage(false);
	}

	if (showMessage)
		return (
			<div className="z-20 rounded-t-lg fixed right-0 bottom-0 bg-blue pl-10 pr-4 pb-4 pt-6 w-full md:w-[26%]">
				<div className="flex justify-between items-center mb-2">
					<H1 className="text-white">Minhas conversas</H1>
					<button onClick={() => setShowMessage(false)}>
						<IoClose size={30} color="white" />
					</button>
				</div>

				<div className="overflow-y-auto max-h-[500px]">
					{conversationList.map((item, index) => {
						return (
							<div
								key={item._id}
								onClick={() => handleLoadConversation(item._id)}
								className="bg-white p-2 hover:ring-2 hover:cursor-pointer flex flex-row items-center mb-2"
							>
								<div className="w-12 h-12 rounded-full overflow-hidden bg-blue flex flex-item items-center justify-center text-white">
									{item.image && (
										<img
											src={item.image.url}
											alt=""
											className="w-full h-full object-cover object-center"
										/>
									)}
									{!item.image && (
										<div className="font-bold text-2xl">
											{item.name.substring(0, 1)}
										</div>
									)}
								</div>
								<div className="ml-4 flex-1">{item.name}</div>
							</div>
						);
					})}
				</div>
			</div>
		);

	return (
		<>
			{conversationList &&
				conversationList.length > 0 &&
				!location.pathname.includes("mensagens") &&
				showModal && (
					<button
						className="fixed right-5 bottom-5"
						onClick={() => setShowMessage(true)}
					>
						<img
							className="h-16 md:h-auto"
							src={Message}
							alt="Icone de mensagem"
						/>
					</button>
				)}
		</>
	);
}
