import CardLoading from "components/loading/CardLoading";
import { useAuth } from "context/AuthContext";
import { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";

import footer from "assets/images/footer.svg";
import Decoration from "components/ui/Decoration";
import {
	BsArrowLeftCircle,
	BsFacebook,
	BsFillPencilFill,
	BsInstagram,
	BsLinkedin,
	BsTwitter,
	BsYoutube,
} from "react-icons/bs";
import Button from "components/ui/Button";
import ProfileForm from "./components/ProfileForm";
import { User } from "types/user";
import { getPlayer } from "services/profile.service";
import { H1 } from "components/typography";
import { Link } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
	checkConnectionStatus,
	fetchNetworkingCard,
} from "services/networking.service";
import { IUserCard } from "types/networking";
import { useModal } from "context/ModalContext";
import { useNetworking } from "context/NetworkingContext";
import ReactModal from "react-modal";

const world = require("assets/images/world-icon.png");
const cloud = require("assets/images/cloud-top-left.png");

export function Profile() {
	const { showModal } = useModal();
	const { user } = useAuth();
	const { networkingCard } = useNetworking();
	const params = useParams();
	const navigate = useNavigate();
	const [isEditing, setIsEditing] = useState(false);
	const [isConnected, setIsConnected] = useState(false);
	const [canEdit, setCanEdit] = useState(false);
	const [modalIsOpen, setIsOpen] = useState(false);

	const [profileNetworkingCard, setProfileNetworkingCard] =
		useState<IUserCard>();

	const [loading, setLoading] = useState(true);

	// const [profileChanged, setProfileChanged] = useState(false);

	// const isDesktopOrLaptop = useMediaQuery({
	// 	query: "(min-width: 1024px)",
	// });

	// const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const footerStyle: CSSProperties = {
		backgroundImage: `url(${footer})`,
		backgroundSize: "100% auto",
		backgroundPosition: "top",
		backgroundRepeat: "no-repeat",
	};

	function editFirstProfile() {
		setIsOpen(false);
		setIsEditing(true);
	}

	function editProfile() {
		setIsEditing(true);
	}

	function closeProfile() {
		setIsEditing(false);
	}

	function hasSocialLinks() {
		if (!profileNetworkingCard) return false;
		return (
			profileNetworkingCard.facebook ||
			profileNetworkingCard.twitter ||
			profileNetworkingCard.instagram ||
			profileNetworkingCard.linkedin
		);
	}

	function handleConnect() {
		showModal("send_invite", { invite: profileNetworkingCard });
	}

	useEffect(() => {
		async function loadData() {
			if (params.id) {
				const networkingCardData = await fetchNetworkingCard(params.id);

				if (user?._id !== params.id) {
					const connectionData = await checkConnectionStatus(
						user?._id,
						params.id
					);
					setIsConnected(connectionData);
				}

				if (networkingCardData) {
					setProfileNetworkingCard(networkingCardData);
				}

				setCanEdit(!!user && user?._id === params.id);
				setLoading(false);
			} else if (user) {
				const networkingCardData = await fetchNetworkingCard(user._id);
				if (!networkingCardData) {
					setIsOpen(true);
				}
				setProfileNetworkingCard(networkingCardData || {});
				setCanEdit(true);
				setLoading(false);
			}
		}

		if (!!user) {
			loadData();
		}
	}, [user, params]);

	useEffect(() => {
		scrollTo({ top: 0 });
	}, []);

	function firstCardModal() {
		return (
			<ReactModal
				isOpen={modalIsOpen}
				contentElement={() => (
					<div className="relative flex flex-col items-center bg-blue rounded-md md:absolute top-[30%] md:top-auto md:bottom-2/4  right-[-10%] md:right-[35%] w-[80%] md:w-[30%] h-max md:h-max">
						<button
							className="absolute md:left-[93%] left-[88%] bg-blue-light rounded-lg w-8 h-8 top-2 text-blue"
							onClick={() => setIsOpen(false)}
						>
							X
						</button>
						<p className="text-xl ml-4 mt-2 text-blue-light text-center">
							Precisamos de algumas informaçoes para
							<br /> concluir seu cadastro no <b>networking</b>!<br />
							Clique no Botão abaixo para Concluir seu cadastro
						</p>

						<button
							className="border bg-blue-light text-blue rounded-md my-4 py-2 px-3 "
							onClick={() => editFirstProfile()}
						>
							Completar perfil
						</button>
					</div>
				)}
			></ReactModal>
		);
	}

	return (
		<>
			<div className="min-h-screen flex flex-col">
				<div
					className="bg-red rounded-bl-xl"
					style={{ background: profileNetworkingCard?.color || "auto" }}
				>
					<div className="relative flex flex-col items-center text-xl md:text-2xl h-44">
						<div
							id="profile-image"
							className="absolute mx-auto bg-blue-500 h-72 w-60 rounded-b-xl bg-no-repeat bg-center bg-cover shadow-xl"
							style={{
								backgroundImage: profileNetworkingCard?.image
									? `url(${profileNetworkingCard?.image.medium.url})`
									: "",
							}}
						>
							<div className="absolute bg-blue-light rounded-full -bottom-8 left-0 right-0 mx-auto w-16 p-1">
								<img src={world} />
							</div>
						</div>
					</div>

					<div className="absolute top-0 left-0">
						<button
							onClick={() => navigate("/")}
							className="absolute w-24 h-20 flex flex-col justify-center items-center text-white text-sm"
						>
							<BsArrowLeftCircle size={24} /> Voltar
						</button>
						{/* <img src={cloud} className="pointer-events-none" /> */}
					</div>
					{firstCardModal()}
					{canEdit && !isEditing && (
						<div className="absolute top-5 right-5">
							<button
								onClick={() => editProfile()}
								className="hover:ring-2 ring-white text-xs text-white rounded-2xl w-16 h-16 bg-purple flex flex-col items-center justify-center"
							>
								<div className="bg-white rounded-full p-2 mb-1">
									<BsFillPencilFill className="text-purple" />
								</div>
								Editar
							</button>
						</div>
					)}
				</div>

				{loading && (
					<div className="px-5 pt-40 mb-20 space-y-10 flex flex-col items-center justify-center">
						<div className="w-64">
							<CardLoading />
						</div>
					</div>
				)}

				{!loading && !profileNetworkingCard && (
					<div>
						<div className="px-5 pt-40 mb-20 space-y-10 flex flex-col items-center justify-center">
							<H1>Perfil não encontrado</H1>
							<p>Que tal realizar uma busca para encontrar mais contatos?</p>
							<Link to="/">
								<Button className="text-white px-5">Buscar pessoas</Button>
							</Link>
						</div>
					</div>
				)}

				{!!profileNetworkingCard && (
					<div className="px-5 pt-40 mb-20 space-y-10 flex flex-col items-center justify-center">
						<div className="text-center">
							<h1 className="text-2xl mb-1 font-bold">
								{profileNetworkingCard?.name || "..."}
							</h1>
							<h2 className="text-xl text-orange-400">
								{profileNetworkingCard?.position}{" "}
								<span className="text-purple">
									- {profileNetworkingCard?.company}
								</span>
							</h2>
							{/* <p className="text-sm">
								{profileNetworkingCard?.city || "---"},{" "}
								{profileNetworkingCard?.state || "---"}
							</p> */}
						</div>

						<div className="flex flex-col md:flex-row items-center md:items-start space-x-10">
							{profileNetworkingCard.segment ? (
								<div>
									<h3 className="text-xl text-gray-500 mb-1">
										<em>Segmento</em>
									</h3>
									<p className="text-purple font-italic">
										<em>{profileNetworkingCard.segment}</em>
									</p>
								</div>
							) : (
								""
							)}

							<div className="w-72 border">
								<h3 className="text-xl text-gray-500 mb-1">
									<em>Sobre mim</em>
								</h3>
								<p className="text-purple font-italic">
									{profileNetworkingCard?.bio || "---"}
								</p>
							</div>

							<div>
								{profileNetworkingCard?.keywords &&
									profileNetworkingCard?.keywords.length > 0 && (
										<div>
											<h3 className="text-xl text-gray-500 mb-1">
												<em>Interesses</em>
											</h3>
											<div className="basis-1/3 flex flex-row items-start justify-start space-x-2 flex-wrap">
												{profileNetworkingCard?.keywords?.map((item) => (
													<p
														key={item}
														className="bg-purple text-white px-3 py-1 flex-0 flex flex-row items-center rounded-full"
													>
														{item}
													</p>
												))}
											</div>
										</div>
									)}
							</div>
						</div>
					</div>
				)}

				<div
					className="relative pt-14  md:pt-24 flex-1 flex "
					style={footerStyle}
				>
					{/* <Decoration
						image="profile"
						className="hidden md:block absolute w-32 md:w-72 -top-64 left-0"
					/> */}

					{!!profileNetworkingCard && (
						<div className="flex-1 bg-purple flex flex-col items-center text-white space-y-10">
							<div className="flex flex-col md:flex-row pl-10 md:pl-0 w-96 justify-center">
								<div className="basis-1/2 mb-4 md:mb-0">
									<p className="mb-2 font-bold">Contato</p>
									<p>{profileNetworkingCard?.email || " "}</p>
									<p>{profileNetworkingCard?.phone || " "}</p>
								</div>
								{hasSocialLinks() && (
									<div>
										<p className="mb-2">Redes</p>
										<div className="flex flex-row space-x-2">
											{profileNetworkingCard?.linkedin && (
												<a
													href={profileNetworkingCard?.linkedin}
													target="_blank"
													rel="opener"
												>
													<BsLinkedin size={24} />
												</a>
											)}
											{profileNetworkingCard?.twitter && (
												<a
													href={profileNetworkingCard?.twitter}
													target="_blank"
													rel="opener"
												>
													<BsTwitter size={24} />
												</a>
											)}
											{profileNetworkingCard?.facebook && (
												<a
													href={profileNetworkingCard?.facebook}
													target="_blank"
													rel="opener"
												>
													<BsFacebook size={24} />
												</a>
											)}
											{profileNetworkingCard?.instagram && (
												<a
													href={profileNetworkingCard?.instagram}
													target="_blank"
													rel="opener"
												>
													<BsInstagram size={24} />
												</a>
											)}
											{profileNetworkingCard?.youtube && (
												<a
													href={profileNetworkingCard?.youtube}
													target="_blank"
													rel="opener"
												>
													<BsYoutube size={24} />
												</a>
											)}
										</div>
									</div>
								)}
							</div>
							{!canEdit && !isConnected && (
								<div className="w-64 pt-8 mb-3">
									<Button className="w-full" onClick={() => handleConnect()}>
										Trocar Cartão
									</Button>
								</div>
							)}
						</div>
					)}
				</div>
			</div>

			{isEditing && (
				<div className="absolute md:top-5 md:right-5 md:bottom-16 bg-white rounded-lg shadow-xl md:w-96 divide-y flex flex-col overflow-hidden">
					<div className="flex flex-row justify-between p-4">
						<p className="text-purple text-lg">Personalize seu perfil!</p>
						<button onClick={() => closeProfile()} className="text-orange-500">
							<AiOutlineCloseCircle size={28} />
						</button>
					</div>
					<div className="flex-1 overflow-y-scroll">
						<ProfileForm onChange={() => closeProfile()} />
					</div>
				</div>
			)}
		</>
	);
}
