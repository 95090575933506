import { UIElementProps } from "types/prop-types";

const cloud = require("assets/images/cloud-top-right.png");
const register = require("assets/images/register-top-right.png");
const recover = require("assets/images/recover-top-right.png");

type DecorationTopRightProps = UIElementProps & {
	image? : "cloud" | "register" | "recover";
};

const source = {
	cloud,
	register,
	recover
}

export default function DecorationTopRight(props:DecorationTopRightProps){

	return <img src={source[props.image || "cloud"]} alt="Nuvem" className="w-full h-full object-contain" {...props} />
}
