import { UIElementProps } from "types/prop-types";

const logoConexao = require("assets/images/logo-HCB.png");

type LogoSebraeProps = UIElementProps & {
	color?: "dark" | "light";
}

export default function LogoSebrae(props: LogoSebraeProps) {
	return (
		<img
			src={logoConexao}
			alt="SEBRAE"
			{...props}
		/>
	);
}
