import { createContext, ReactNode, useContext, useState } from "react";

interface ModalContextData {
	acceptCall: (data: callObject) => void;
	recivedCall: callObject | undefined;
	maxmized?: boolean;
	setMaxmized: (data: boolean) => void;
	call?: string;
	setCall: (data: string | undefined) => void;
	callUser?: string;
	setCallUser: (data: string) => void;
}

interface CallProviderProps {
	children: ReactNode;
}

interface callObject {
	meet: string;
	player: string;
}

export const CallContext = createContext({} as ModalContextData);

function CallProvider({ children }: CallProviderProps) {
	const [recivedCall, setRecivedCall] = useState<callObject>();
	const [maxmized, setMaxmized] = useState<boolean>();
	const [call, setCall] = useState<undefined | string>(undefined);
	const [callUser, setCallUser] = useState<string>("");

	function acceptCall(Call: callObject) {
		setRecivedCall(Call);
	}

	return (
		<CallContext.Provider
			value={{
				acceptCall,
				recivedCall,
				setMaxmized,
				maxmized,
				call,
				setCall,
				callUser,
				setCallUser,
			}}
		>
			{children}
		</CallContext.Provider>
	);
}

function useCall() {
	const context = useContext(CallContext);
	if (context === undefined) {
		throw new Error("useWebsocket must be used within a WebsocketProvider");
	}
	return context;
}

export { CallProvider, useCall };
